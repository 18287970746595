
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import Highcharts from 'highcharts';
import PieChart from "highcharts-react-official";


export default function BarChart({
  series,
  title
}: {
    series: any,
    title:string
}) {
  const { t } = useTranslation();
 

  
  const options = {
    chart: {
      type: "column"
    },
    title: {
      text: title
    },
    xAxis: {
    	
      labels: {
        enabled: false
    }
  },
    yAxis: {
      title: {text:'Quantidade'},
      allowDecimals: false,
      labels: {               
        formatter: function (e:any):string  {
            return '' + e.value;
        }
      }
    
    },

    credits: {
      enabled: false
    },
    series: series.series
  };
  
  return (
    <PieChart highcharts={Highcharts} options={options} />
  )
}
