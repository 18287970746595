import { useGlobalState } from "../globalState/GlobalStateProvider";
import { BiUserCircle } from "@react-icons/all-files/bi/BiUserCircle";
import { BiChevronDown } from "@react-icons/all-files/bi/BiChevronDown";
import { BiLogOutCircle } from "@react-icons/all-files/bi/BiLogOutCircle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContextMenu from "./ContextMenu";
import Divider from "./Divider";
const TopbarUserInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {state} = useGlobalState();
  const abbr = () => {
    if (!state.user?.nomeUsuario) return "";

    const splited = state.user?.nomeUsuario.split(" ") || [];
    const first = splited[0];
    const last = splited[splited.length - 1];

    return `${first.substring(0, 1)}${last.substring(0, 1)}`;
  };

  const handleLogout = () => {
    localStorage.removeItem('localState');
    window.location.href='/creditbureau/login';

  };

  return (
    <div>
      <ContextMenu
        buttonProps={{
          variant: "ghost",
          style: {
            boxShadow: "none",
            color: "white",
            background:"transparent",
            border:"none",
            paddingLeft: 0,
            paddingRight: 0,
            marginRight: "16px",
            display:"flex",
            "&:hover": { background: "transparent", boxShadow: "none" },
          },
        }}
        buttonLabel={
          <>
            {state.user?.nomeUsuario || ""}{" "}
            <BiUserCircle
              style={{ marginLeft: "8px", marginRight: "8px", marginTop:"-3px", fontSize: "20px" }}
            />{" "}
            <BiChevronDown style={{ fontSize: "20px", marginTop:"-3px", }} />
          </>
        }
        cardProps={{ style: { padding: 0 } }}
      >
        <div style={{position: "absolute", right: "0px", top: "56px", minWidth: "300px", padding: "0px", border:"1px solid", backgroundColor:"white", borderColor:"#e0e0e0", boxSizing: "border-box", borderRadius: "8px"}}>
            <button
            
            style={{
                justifyContent: "flex-start",
                padding: "0 16px",
                height: "100px",
                width:"100%",
                background:"transparent",
                border:"none",
                cursor:'pointer',
                display: "inline-flex"
              }}
           
            onClick={() => navigate("/alterarsenha")}
            >
            <span
                style={{
                background: "#ccc",
                borderRadius: "50%",
                marginTop:'5%',
                width: "60px",
                height: "60px",
                lineHeight: "60px",
                marginRight: "16px",
                }}
            >   
                {abbr()}
            </span>

            <span
                
                style={{ flexGrow: 1, textAlign: "left", marginTop:'13%', height: "60px" }}
            >
                {state.user?.nomeUsuario || ""}
                
            </span>
            </button>
            <Divider style={{ margin: 0 }} />
            <button
                style={{ justifyContent: "flex-start", padding: "0 16px",  background:"transparent", border:"none", cursor:'pointer', width:"100%", WebkitJustifyContent:"flex-start", height:"40px"}}
                onClick={handleLogout}
            >
                <><div><BiLogOutCircle style={{color:"red", fontSize:"18px", lineHeight: "60px"}}/><span style={{marginTop: "-20px"}}>{t("logout")}</span></div></>
            </button>
        </div>
      </ContextMenu>
    </div>
  );
};

export default TopbarUserInfo;
