
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import { useEffect, useState } from "react";




export default function DadosConsultaPJ({
  dadosConsultaPJ,
  capitalSocial
}: {
    dadosConsultaPJ: any,
    capitalSocial: string
}) {
  const { t } = useTranslation();
  const [cnaeSecundario, setCnaeSecundario] = useState<string>();
  const [filiais, setFiliais] = useState<string>();
  useEffect(() => {
    if(dadosConsultaPJ.cnaeSecundario){
      let result = '';
      dadosConsultaPJ.cnaeSecundario.map((opcao:string) => {
        if(result.length == 0){
          result = result.concat(opcao);
        }
        else{
          result = result.concat(', ').concat(opcao);
        }
        
      })
      setCnaeSecundario(result)
    }
    if(dadosConsultaPJ.filiaisRegiao){
      let resultFiliais = '';
      dadosConsultaPJ.filiaisRegiao.map((opcao:string) => {
        if(resultFiliais.length == 0){
          resultFiliais = resultFiliais.concat(opcao);
        }
        else{
          resultFiliais = resultFiliais.concat(', ').concat(opcao);
        }
        
      })
      setFiliais(resultFiliais)
    }

  }, []);
  return (
    <Panel header={t('dadosConsulta')} style={{width:'98%'}}>
       
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('razaoSocial')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.razaoSocial} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('nomeFantasia')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.nomeFantasia ? dadosConsultaPJ.nomeFantasia : 'Não Informado' } />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('documento')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.documento} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('situacao')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.situacao ? dadosConsultaPJ.situacao : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('cnae')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.cnae ? dadosConsultaPJ.cnae : 'Não Informado'} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('dataAbertura')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.dataAbertura ? dadosConsultaPJ.dataAbertura : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('fone')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.telefone ? dadosConsultaPJ.telefone : 'Não Informado'} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('email')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.email ? dadosConsultaPJ.email : 'Não Informado'} />
            </div>
          </div>
      </div>
      
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('endereco')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.endereco ? dadosConsultaPJ.endereco : 'Não Informado'} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('capitalSocial')}</label>
                 <Chip style={{width:"100%"}} label={capitalSocial ? capitalSocial : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('nire')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.nire ? dadosConsultaPJ.nire : 'Não Informado'} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('tipo')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.tipo ? dadosConsultaPJ.tipo : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('naturezaJuridica')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.naturezaJuridica ? dadosConsultaPJ.naturezaJuridica : 'Não Informado'} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('porte')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.porte ? dadosConsultaPJ.porte : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('colaboradores')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.quantidadeColaboradores ? dadosConsultaPJ.quantidadeColaboradores : 'Não Informado'} />
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('filiais')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsultaPJ.quantidadeFiliais ? dadosConsultaPJ.quantidadeFiliais : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('cnae')} Secundário</label>
                
                <Chip style={{marginLeft: '0.5rem',width:"100%"}} label={cnaeSecundario ? cnaeSecundario : 'Não Informado'} />
                 
            </div>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('filiaisRegiao')}</label>
                
                <Chip style={{marginLeft: '0.5rem',width:"100%"}} label={filiais ? filiais : 'Não Informado'} />
                 
            </div>
          </div>
      </div>
    </Panel>
  )
}
