
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function ParticipacaoEmpresa({
  participacoes
}: {
  participacoes
  : any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('participacaoEmpresa')} style={{width:'98%'}}>
       
       <DataTable size="small" value={  participacoes
} tableStyle={{ minWidth: '50rem' }}>
          <Column field="documentoParticipante" header={t('documentoParticipante')}></Column>
          <Column field="participante" header={t('participante')}></Column>
          <Column field="documentoEmpresa" header={t('documentoEmpresa')}></Column>
          <Column field="empresa" header={t('empresa')}></Column>
          <Column field="tipo" header={t('tipo')}></Column>
          <Column field="percentual" header={t('percentual')}></Column>
          <Column field="dataInicio" header={t('dataInicio')}></Column>
          <Column field="dataAtualizacao" header={t('dataAtualizacao')}></Column>
      </DataTable>
    </Panel>
  )
}
