import {useEffect, useRef, useState} from 'react';
import './App.css';
import { http } from './config/axios'
import './config/i18n'
import { GlobalStateProvider, getState} from "./components/globalState/GlobalStateProvider";
import { GlobalSaveData, GlobalState} from "./components/globalState/GlobalSaveData";
import LoadingIndicator from './components/LoadingIndicator';
import RouterCustom from './components/RouterCustom';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import { locale, addLocale} from 'primereact/api';
import pt  from "./content/locales.json";
import { Toast } from 'primereact/toast';
import getRelatorioNotificacao from './service/getRelatorioNotificacao';

function App() {
  
  const [state, setState] = useState<any>(getState())
  const [loading, setLoading] = useState<boolean>(false)
  const initialized = useRef(false)
  const toast = useRef<Toast>(null);
  let loadingCount = 0 as number
  locale('pt');
  addLocale('pt', pt['pt'])
  let interval;
  const ping_interval = 80000;
  


  useEffect(
    () => {
      if (state?.token && !initialized.current) {
        initialized.current = true
        const socket = new WebSocket(process.env.REACT_APP_API?.toString().replace('http', 'ws') + '/websocket/' + state.token)
        socket.addEventListener("message", event => {
          
          getRelatorioNotificacao().then((data) => {
            GlobalSaveData("relatorios", data);
          });
          if(toast != null && toast.current != null && !event.data.includes('ping')){
            toast.current.show({ severity: 'info', summary: 'Mensagem', detail: event.data, sticky: true });
          }
        });   
      }
      
      
    },
    [state]
  );
  
  
  
 

    
  http.interceptors.request.use(
    function (config) {
      
      
      if(!config.headers.ignoreLoading){
        loadingCount = loadingCount + 1
        setLoading(true)
      }
        
      
      
      
      return { ...config, headers: config.headers }
    },
    function (error) {
      loadingCount = loadingCount - 1
      if(loadingCount < 0)
        loadingCount = 0;
      if (loadingCount === 0) {
        setLoading(false)
      }
      return Promise.reject(error)
    }
  )

  http.interceptors.response.use(
    function (response) {
     
        loadingCount = loadingCount - 1
        if(loadingCount < 0)
          loadingCount = 0;
        if (loadingCount === 0) {
          setLoading(false)
        }
      
      

      return response
    },
    function (error) {
      loadingCount = loadingCount - 1
      if(loadingCount < 0)
        loadingCount = 0;
      if (loadingCount === 0) {
        setLoading(false)
      }
      return Promise.reject(error)
    }
   

  )
  return (
    <div className="App">
      <Toast ref={toast} />
      <PrimeReactProvider>
        <GlobalStateProvider>
          <GlobalState></GlobalState>
          <LoadingIndicator isLoading={loading}></LoadingIndicator>
          <RouterCustom></RouterCustom>
        </GlobalStateProvider>
      </PrimeReactProvider>
    </div>
  );
}

export default App;
