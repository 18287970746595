import ClipLoader from "react-spinners/ClipLoader";

type Props = {
  isLoading: boolean
}

const LoadingIndicator = ({ isLoading }: Props) => {
  if (isLoading)
    return (
      <div
        
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          opacity: '0.7',
          background: '#fff',
          top: 0,
          left: 0,
          zIndex: 2000
        }}
      >
        
        <div style={{ display: "block",
            margin: "0 auto",
            marginTop:"15%",
            marginLeft:"50%",
            position: "absolute"}}><ClipLoader size={50} color="#0547b0" /></div>
      </div>
    )
  else return <div></div>
}

export default LoadingIndicator
