
import { useTranslation } from "react-i18next";
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import NadaConsta from "../../../components/nadaConsta";




export default function Negativacoes({
  negativacoes
}: {
  negativacoes: any
}) {
  const { t } = useTranslation();

  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> {negativacoes && negativacoes.length > 0 ? (<span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('restricoesMercado')}</span></span>) : (<span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('restricoesMercado')}</span>) }</div>;
  }
  return (
    <Panel headerTemplate={headerTemplate} style={{width:'98%'}}>
        {!negativacoes || negativacoes.length === 0  ? ( <NadaConsta />) : (<>
       <DataTable emptyMessage="Nenhum Registro" size="small" value={negativacoes} tableStyle={{ minWidth: '50rem' }}>
          <Column field="dataRegistro" header={t('dataRegistro')}></Column>
          <Column field="motivo" header={t('natureza')}></Column>
          <Column field="contrato" header={t('contrato')}></Column>
          <Column field="empresaFilial" header={t('credor')}></Column>
          <Column field="valor" header={t('valor')}></Column>
      </DataTable>
      </>)}
    </Panel>
  )
}
