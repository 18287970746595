
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function CCFS({
  ccfs
}: {
  ccfs: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('ccfs')} style={{width:'98%'}}>
       
       <DataTable size="small" value={ccfs} tableStyle={{ minWidth: '50rem' }}>
          <Column field="tipo" header={t('tipo')}></Column>
          <Column field="codigo" header={t('codigoBanco')}></Column>
          <Column field="banco" header={t('banco')}></Column>
          <Column field="agencia" header={t('agencia')}></Column>
          <Column field="motivo" header={t('motivo')}></Column>
          <Column field="quantidade" header={t('quantidade')}></Column>
          <Column field="ultimaOcorrencia" header={t('dataUltimaOcorrencia')}></Column>
      </DataTable>
    </Panel>
  )
}
