import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findUser from '../../service/findUser';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { SortOrder } from 'primereact/api';
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Nullable } from 'primereact/ts-helpers';
import { Toolbar } from 'primereact/toolbar';
import { useGlobalState } from '../../components/globalState/GlobalStateProvider';


export default function UsuarioList({onEdit}:{onEdit:(user:any)=>void}) {
  const { t } = useTranslation();
  const [users, setUsers] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const [login, setLogin] = useState<Nullable<String | null>>(null);
  const [nome, setNome] = useState<Nullable<String | null>>(null);
  const {state} = useGlobalState();
  
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    login: login,
    nome: nome,
    sortField: 'h.data',
    sortOrder: SortOrder.DESC
  });

  

  const cols = [
    { field: 'nome', header: 'Nome' },
    { field: 'login', header: 'Login' },
    { field: 'dataUltimoLogin', header: 'Data Último Login' },
    { field: 'ipUltimoLogin', header: 'IP Último Login' },
    { field: 'origemLogin', header: 'Origem Login' },
    { field: 'inativo', header: 'Inativo' }
];
  const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
        import('jspdf-autotable').then(() => {
            const lazy = {
              first: 0,
              rows: totalRecords,
              page:  1,
              login: login,
              nome: nome,
              sortField: 'h.data',
              sortOrder: SortOrder.DESC
            }
            findUser(lazy).then(data => {
              const doc : any = new jsPDF.default('portrait');
              doc.autoTable(exportColumns, data.usuarios);
              doc.save('usuarios.pdf');
            });
            
        });
    });
};

const exportExcel = () => {
  import('xlsx').then((xlsx) => {
    const lazy = {
      first: 0,
      rows: totalRecords,
      page:  1,
      login: login,
      nome: nome,
      sortField: 'h.data',
      sortOrder: SortOrder.DESC
    }
    findUser(lazy).then(data => {
      const users: any[] = []
      data.usuarios.forEach((user:any) => users.push({nome:user.nome, email:user.email, login:user.login, cliente:user.nomeCliente, dataUltimoLogin: user.dataUltimoLogin, ipUltimoLogin: user.ipUltimoLogin, origemLogin: user.origemLogin, inativo: user.inativo}))
      
      const worksheet = xlsx.utils.json_to_sheet(users);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array'
      });
      saveAsExcelFile(excelBuffer, 'usuarios');
    });

      
  });
};
const saveAsExcelFile = (excelBuffer: any, fileName: string) => {
  import('file-saver').then((module) => {
      if (module && module.default) {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([excelBuffer], {
              type: EXCEL_TYPE
          });

          module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
  });
};

  const changeLogin = (log:Nullable<String>) => {
    if(log){
      setLogin(log);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        login: log,
        nome: nome,
        sortField: 'h.data',
        sortOrder: SortOrder.DESC
      });
    }
    else{
      setLogin(null);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        login: null,
        nome: nome,
        sortField: 'h.data',
        sortOrder: SortOrder.DESC
      });
    }
    
  
  }

  const changeNome = (nom:Nullable<String>) => {
    if(nom){
      setNome(nom);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        login: login,
        nome: nom,
        sortField: 'h.data',
        sortOrder: SortOrder.DESC
      });
    }
    else{
      setNome(null);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        login: login,
        nome: null,
        sortField: 'h.data',
        sortOrder: SortOrder.DESC
      });
    }
    
  
  }

  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      login: login,
      nome: nome,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      login: login,
      nome: nome,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const clearFields = () => {
    setLogin(null);
    setNome(null);
    (document.getElementById('usuario') as HTMLInputElement).value = '';
    (document.getElementById('nome') as HTMLInputElement).value = '';
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      login: null,
      nome: null,
      sortField: 'h.data',
      sortOrder: SortOrder.DESC
    });
  };
  
  const header = (
    <div className="flex-consulta align-items-center justify-content-end gap-2">
        <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
        <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
    </div>
  );

  const startContent = (
    <React.Fragment>
      <div style={{ background: 'transparent' }}>
        <div className="grid">
  
          <div className="col p-fluid flex flex-wrap">
            <div>
              <div className="flex-auto">
                <label htmlFor="usuario" className="font-bold block mb-2">{t('nome')}</label>
                <InputText id="usuario" onBlur={(e) => changeNome(e.target.value)} style={{ width: '20rem' }} required />
              </div>
            </div>
          </div>
  
          <div className="col p-fluid flex flex-wrap">
            <div>
              <div className="flex-auto">
                <label htmlFor="nome" className="font-bold block mb-2">{t('login')}</label>
                <InputText id="nome" onBlur={(e) => changeLogin(e.target.value)} style={{ width: '20rem' }} />
              </div>
            </div>
          </div>
          <Button label='Limpar' icon="pi pi-times" onClick={clearFields} className="p-button-outlined" style={{ height: '3rem', marginTop: '1.8rem' }} />
        </div>
      </div>
    </React.Fragment>
  );

  const loadLazyData = () => {
    findUser(lazyState).then(data => {setTotalRecords(data.total);setUsers(data.usuarios)}).catch(() => {setTotalRecords(0);setUsers(undefined)});
  }

  const inativoBodyTemplate = (user:any) => {
    return <InputSwitch disabled checked={user.inativo} onChange={() => user.inativo = !user.inativo} />;
  };

  const cacheBodyTemplate = (user:any) => {
    return <InputSwitch disabled checked={user.cache} onChange={() => user.cache = !user.cache} />;
  };

  const editBodyTemplate = (user:any) => {
    return <Button icon="pi pi-pencil" onClick={() => onEdit(user)} style={{background:'transparent', color:'gray', border: 'none'}} />;
  };
  
  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <div>
      <div>
        <Toolbar start={startContent} />
        <br></br>
        <br></br>
        <DataTable value={users} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros" header={header}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           {!state.user.cliente && (<Column field="nomeCliente" header={t('cliente')}  />)}
           <Column field="nome" sortable header={t('nome')}  />
           <Column field="login" sortable header={t('login')}  />
           <Column field="dataUltimoLogin" sortable sortField='h.data' header={t('dataUltimoLogin')}  />
           <Column field="ipUltimoLogin" header={t('ipUltimoLogin')}  />
           <Column field="origemLogin" header={t('origem') + ' ' + t('login')}  />
           <Column header={t('inativo')} body={inativoBodyTemplate} /> 
           {state.user.funcoes.includes('USUARIO_CACHE') && (
            <Column header={t('cache')} body={cacheBodyTemplate} /> 
           )}
           <Column body={editBodyTemplate} />
        </DataTable> 
      </div>
    </div>
  )
}


