
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";




export default function Cadastral({
  pessoa
}: {
    pessoa: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('cadastralCOPQ')} style={{width:'98%'}}>
       
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('nome')}</label>
                 <Chip style={{width:"100%"}} label={pessoa.nome} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('sexo')}</label>
                 <Chip style={{width:"100%"}} label={pessoa.sexo} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('documento')}</label>
                 <Chip style={{width:"100%"}} label={pessoa.documento} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('dataNascimento')}</label>
                 <Chip style={{width:"100%"}} label={pessoa.dataNascimento} />
            </div>
          </div>
      </div>
     
    </Panel>
  )
}
