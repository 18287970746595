import { BiMenu } from "@react-icons/all-files/bi/BiMenu";
import logo from "../../assets/logogziplogin.jpeg";
import logoCredidBureau from "../../assets/imagesmenu.png";
import './Topbar.css';
import { useGlobalState } from "../globalState/GlobalStateProvider";
import { GlobalSaveData } from "../globalState/GlobalSaveData";
import TopbarUserInfo from "./TopbarUserInfo";
import TopbarRelatorio from "./TopbarRelatorio";

const Topbar = () => {
  const {state} = useGlobalState();
  const handleToggleSidebar = () => {
   
    GlobalSaveData("sidebarOpen", !state.sidebarOpen);
  };

  return (
    <div id="interface-topbar">
      <div className="wrapper">
       <div  style={{paddingLeft:'10px'}}>
          <button onClick={handleToggleSidebar} style={{background:'transparent', border:'none'}}> 
            <BiMenu style={{color:'white', fontSize:'30px', cursor:'pointer'}} />
          </button>
        </div>
       
      <div style={{paddingLeft:'10px', flexGrow: 1}}><img src={state.user.nomeUsuario == 'Deoclides Quevedo' ? logoCredidBureau : logo} style={{width:'250px', height:'55px', paddingTop:'3px'}} alt="Logo" /></div>
       
       <TopbarRelatorio />
       <TopbarUserInfo />

        
      </div>
    </div>
  );
};

export default Topbar;
