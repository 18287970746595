
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function Aviso({
  avisos
}: {
  avisos: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('avisos')} style={{width:'98%'}}>
       { avisos.map((aviso:string) => {
        return (<div>{aviso}</div>)
       }) }
       
    </Panel>
  )
}
