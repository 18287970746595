
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect, useState } from "react";




export default function Analise({
  analises
}: {
  analises
  : any
}) {
  const { t } = useTranslation();
  const [analisesTexto, setAnalisesTexto] = useState<[any]>();
  useEffect(() => {
    const ana :any = [];
    analises.map((analise:string) => {
      ana.push({analise:analise})
    })
    setAnalisesTexto(ana)
  },[])
  return (
    <Panel header={t('analises')} style={{width:'98%'}}>
       
       <DataTable size="small" value={  analisesTexto
} tableStyle={{ minWidth: '50rem' }}>
          <Column field="analise" header=""></Column>
      </DataTable>
    </Panel>
  )
}
