
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function LinhaCredito({
  linhaCredito
}: {
  linhaCredito
  : any
}) {
  const { t } = useTranslation();

  return (
    <Panel header={t('linhaCredito')} style={{width:'98%'}}>
       
       <DataTable size="small" value={linhaCredito.data} tableStyle={{ minWidth: '50rem' }}>
        {linhaCredito.columns.map((col:any) => (
                    <Column key={col.field} field={col.field} header={col.header} />
                ))}
      </DataTable>
    </Panel>
  )
}
