
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function Acoes({
  acoes
}: {
  acoes: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('acoes')} style={{width:'98%'}}>
       
       <DataTable size="small" value={acoes} tableStyle={{ minWidth: '50rem' }}>
          <Column field="numero" header={t('numero')}></Column>
          <Column field="autor" header={t('autor')}></Column>
          <Column field="comarca" header={t('comarca')}></Column>
          <Column field="forum" header={t('forum')}></Column>
          <Column field="vara" header={t('vara')}></Column>
          <Column field="cidadeUF" header={t('cidadeUF')}></Column>
          <Column field="dataProcesso" header={t('dataProcesso')}></Column>
          <Column field="dataDistribuicao" header={t('dataDistribuicao')}></Column>
          <Column field="tipo" header={t('tipo')}></Column>
          <Column field="origem" header={t('origem')}></Column>
      </DataTable>
    </Panel>
  )
}
