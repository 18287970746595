import { http } from '../../config/axios'
import { FilterWeb } from '../../interface/FilterWeb'

export default function postConsultaWeb(filterWeb: FilterWeb): Promise<string> {
  return http
    .post('api/web/process', filterWeb)
    .then((res) => {
      return res.data as string
    })
    .catch((error) => {
      console.error('Erro ao fazer Consulta', error)
      throw Error(error.response.data)
    })
}
