
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import Highcharts from 'highcharts';
import bullet from "highcharts/modules/bullet.js";
import HighchartsReact from 'highcharts-react-official';

bullet(Highcharts);

export default function RendaPresumida({
  rendaPresumida
}: {
  rendaPresumida: any
}) {
  const { t } = useTranslation();
 console.log(rendaPresumida)

 const options = {
    
  chart: {
      inverted: true,
      type: 'bullet',
      height: 100
  },
  title: {
      text: null
  },
  legend: {
      enabled: false
  },
  plotOptions: {
      series: {
          pointPadding: 0.25,
          borderWidth: 0,
          color: '#000'
          
      }
  },
  credits: {
      enabled: false
  },
  exporting: {
      enabled: false
  },


  xAxis: {
      categories: ['']
  },
  tooltip:{
    enabled: false   
  },
  yAxis: {
    gridLineWidth: 0,
      max:30000,
      plotBands: [{
          color: {
              linearGradient: { x1: 0, y1: 1, x2: 1, y2: 1, x3: 2, y3: 1, x4: 3, y4: 1 },
              stops: [
                  [0, 'rgb(178 34 34)'],
                 [1, 'rgb(60 179 113)']
              ]
          },
          from: 0,
          to: 30000
      }],
      title: null
  },
  series: [{
      data: [{
          y: parseInt(rendaPresumida.renda ? rendaPresumida.renda.split(',')[0].replace('.', '') : 0),
          color: '#F5F5DC'
      }]
  }]


};
  const optionsPrint = {
    
      chart: {
          inverted: true,
          type: 'bullet',
          height: 100,
          width: 750
      },
      title: {
          text: null
      },
      legend: {
          enabled: false
      },
      plotOptions: {
          series: {
              pointPadding: 0.25,
              borderWidth: 0,
              color: '#000'
              
          }
      },
      credits: {
          enabled: false
      },
      exporting: {
          enabled: false
      },
  
  
      xAxis: {
          categories: ['']
      },
      tooltip:{
        enabled: false   
      },
      yAxis: {
        gridLineWidth: 0,
          max:30000,
          plotBands: [{
              color: {
                  linearGradient: { x1: 0, y1: 1, x2: 1, y2: 1, x3: 2, y3: 1, x4: 3, y4: 1 },
                  stops: [
                      [0, 'rgb(178 34 34)'],
                     [1, 'rgb(60 179 113)']
                  ]
              },
              from: 0,
              to: 30000
          }],
          title: null
      },
      series: [{
          data: [{
              y: parseInt(rendaPresumida.renda ? rendaPresumida.renda.split(',')[0].replace('.', '') : 0),
              color: '#F5F5DC'
          }]
      }]
  
  
  };
  return (
    <div>
       
      {rendaPresumida.renda && (
        <>
          <Panel header={t('rendaPresumida')} style={{width:'98%'}}>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label className="label-dados">{t('renda')}</label>
                  <Chip style={{width:"100%"}} label={rendaPresumida.renda} />
                </div>
              </div>
              <div className="col">
                <div >
                  <label className="label-dados">{t('faixa')}</label>
                  <Chip style={{width:"100%"}} label={rendaPresumida.faixa} />
                </div>
              </div>
            </div>
            <div className="div-renda">
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <div className="div-renda-print">
              <HighchartsReact highcharts={Highcharts} options={optionsPrint} />
            </div>
            <br></br>
          </Panel>
          <br></br>
          <br></br>
        </>
       
      )}
       {rendaPresumida.rendaGasta && (
        <>
          <Panel header={t('gastoEstimado')} className="panel-renda" style={{width:'98%'}}>
            <div className="col-12">
              <div className="card-gasto p-0 grid grid-nogutter">
                <div className="col-12 md:col-6 lg:col-3 py-5 px-6 border-none md:border-right-1 surface-border">
                  <div className="flex-score align-items-center mb-3">
                    <div className="text-base bg-indigo-100 text-indigo-700 p-avatar p-component p-avatar-circle p-avatar-lg" data-pc-name="avatar" data-pc-section="root">
                      <span className="p-avatar-icon pi pi-dollar" data-pc-section="icon"></span>
                    </div>
                    <span className="text-xl ml-2">Renda Gasta</span>
                  </div>
                <div style={{minHeight:'65px'}} className="flex-score align-items-center justify-content-between mb-3">
                  <span className="block font-bold text-xl mb-3">{rendaPresumida.rendaGasta}</span>
                  <div className="flex-score align-items-center justify-content-between h-2rem px-2 py-1 bg-red-100 text-red-900 border-round">
                    <i className="pi pi-chart-pie"></i>
                    <span style={{fontSize:'12px', width:'4rem'}} className="font-bold ml-1">{rendaPresumida.rendaGastaPercentual}</span>
                  </div>
                </div>
                <div className="border-round overflow-hidden surface-200" style={{height: '0.5rem'}}>
                  <div className="h-full bg-indigo-500" style={{width: rendaPresumida.rendaGastaPercentual ? rendaPresumida.rendaGastaPercentual.split('%')[0].replaceAll('Até ', '') : 0 + '%'}}>
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-6 lg:col-3 py-5 px-6 border-none md:border-right-1 surface-border">
                <div className="flex-score align-items-center mb-3">
                  <div className="text-base bg-green-100 text-green-700 p-avatar p-component p-avatar-circle p-avatar-lg" data-pc-name="avatar" data-pc-section="root">
                    <span className="p-avatar-icon pi pi-credit-card" data-pc-section="icon"></span>
                  </div><span className="text-xl ml-2">Cartão Crédito</span>
                </div>
                <div style={{minHeight:'65px'}} className="flex-score align-items-center justify-content-between mb-3">
                  <span className="block font-bold text-xl mb-3">{rendaPresumida.rendaGastaCartaoCredito}</span>
                  <div className="flex-score align-items-center justify-content-between h-2rem px-2 py-1 bg-red-100 text-red-900 border-round">
                    <i className="pi pi-chart-pie"></i>
                    <span style={{fontSize:'12px', width:'4rem'}} className="font-bold ml-1">{rendaPresumida.rendaGastaCartaoCreditoPercentual}</span>
                  </div>
                </div>
                <div className="border-round overflow-hidden surface-200" style={{height: '0.5rem'}}>
                  <div className="h-full bg-green-500" style={{width: rendaPresumida.rendaGastaCartaoCreditoPercentual ? rendaPresumida.rendaGastaCartaoCreditoPercentual.split('%')[0].replaceAll('Até ', '') : 0 + '%'}}>
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-6 lg:col-3 py-5 px-6 border-none md:border-right-1 surface-border">
                <div className="flex-score align-items-center mb-3">
                  <div className="text-base bg-yellow-100 text-yellow-700 p-avatar p-component p-avatar-circle p-avatar-lg" data-pc-name="avatar" data-pc-section="root">
                    <span className="p-avatar-icon pi pi-arrow-up-right" data-pc-section="icon"></span>
                  </div><span className="text-xl ml-2">Parcelas</span>
                </div>
                <div style={{minHeight:'65px'}} className="flex-score align-items-center justify-content-between mb-3">
                  <span className="block font-bold text-xl mb-3">{rendaPresumida.rendaGastaParcela}</span>
                  <div className="flex-score align-items-center justify-content-between h-2rem px-2 py-1 bg-red-100 text-red-900 border-round">
                    <i className="pi pi-chart-pie"></i>
                    <span style={{fontSize:'12px', width:'4rem'}} className="font-bold ml-1">{rendaPresumida.rendaGastaParcelaPercentual}</span>
                  </div>
                </div>
                <div className="border-round overflow-hidden surface-200" style={{height: '0.5rem'}}>
                  <div className="h-full bg-yellow-500" style={{width: rendaPresumida.rendaGastaParcelaPercentual ? rendaPresumida.rendaGastaParcelaPercentual.split('%')[0].replaceAll('Até ', '') : 0 + '%'}}>
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-6 lg:col-3 py-5 px-6">
                <div className="flex-score align-items-center mb-3">
                  <div className="text-base bg-purple-100 text-purple-700 p-avatar p-component p-avatar-circle p-avatar-lg" data-pc-name="avatar" data-pc-section="root">
                    <span className="p-avatar-icon pi pi-money-bill" data-pc-section="icon"></span>
                  </div><span className="text-xl ml-2">Outros Gastos</span>
                </div>
                <div className="flex-score align-items-center justify-content-between ">
                  <span className="block font-bold  mb-3">Financiamento Imóvel</span>
                  <div className="flex-score align-items-center justify-content-between h-2rem border-round">
                    <span className={rendaPresumida.financimanetoImobiliario === 'Sim' ? 'dot-gasto-purple mb-3' : 'dot-gasto mb-3'}></span>
                  </div>
                </div>
                <div className="flex-score align-items-center justify-content-between ">
                  <span className="block font-bold  mb-3">Financiamento Veículo</span>
                  <div className="flex-score align-items-center justify-content-between h-2rem border-round">
                    <span className={rendaPresumida.financimanetoVeiculo === 'Sim' ? 'dot-gasto-purple mb-3' : 'dot-gasto mb-3'}></span>
                  </div>
                </div>
                <div className="flex-score align-items-center justify-content-between ">
                  <span className="block font-bold  mb-3">Consórcio</span>
                  <div className="flex-score align-items-center justify-content-between h-2rem border-round">
                    <span className={rendaPresumida.consorcio === 'Sim' ? 'dot-gasto-purple mb-3' : 'dot-gasto mb-3'}></span>
                  </div>
                </div>
                <div className="flex-score align-items-center justify-content-between ">
                  <span className="block font-bold  mb-3">Outras Parcelas</span>
                  <div className="flex-score align-items-center justify-content-between h-2rem border-round">
                    <span className={rendaPresumida.outrasParcelas === 'Sim' ? 'dot-gasto-purple mb-3' : 'dot-gasto mb-3'}></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Panel>
        </>
       )}
   </div>
  )
}
