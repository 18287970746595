import { http } from '../../config/axios'

export default function getPermissaoUser(): Promise<any[]> {
  return http
    .get('api/permissao/user')
    .then((res) => {
      return res.data as any[]
    })
    .catch((error) => {
      console.error('Erro getPermissaoUser', error)
      throw Error(error.response.data)
    })
}
