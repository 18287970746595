import { http } from '../../config/axios'
import { UserCredentials } from '../../interface/UserCredentials'

export default function postLogin(postLoginForm: UserCredentials): Promise<string> {
  return http
    .post('api/gatewaybiro/authentication', postLoginForm, {
      headers: {
          'originLogin': 'web'
      }
  })
    .then((res) => {
      return res.data.token as string
    })
    .catch((error) => {
      console.error('Erro ao fazer Login', error)
      throw Error(error.response.data)
    })
}
