import { useRef, useState } from 'react';
import logo from '../../assets/logogzip.jpeg';
import logoCreditBureau from '../../assets/logogzipquod.jpeg';
import postRedefinirSenha from '../../service/postRedefinirSenha/postRedefinirSenhaService';
import { useTranslation } from 'react-i18next';
import { Password } from 'primereact/password';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';


export default function RedefinirSenha() {
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [show, setShow] = useState<boolean>(true);
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const navigate = useNavigate();

  const submit = () => {
    if(!password || !passwordRepeat || password != passwordRepeat){
      msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: 'Nova senha e confirma nova senha não são iguais', closable: false }
        
      ]);
    }
    else{
      setTimeout(() =>{
        const request = {
          password: password,
          hash: window.location.href.split('/')[window.location.href.split('/').length-1]
        }
        postRedefinirSenha(request)
                .then((data) => {
                  setShow(false)
                  msgs.current?.clear();
                  msgs.current?.show([
                      { sticky: false, life: 100000, severity: 'info', summary: 'Info', detail: data, closable: false }
                    
                  ]);
                  
                })
                .catch((error) => {
                  msgs.current?.clear();
                  msgs.current?.show([
                      { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: 'Erro ao alterar Senha', closable: false }
                    
                  ]);
                })
              },1000);
    }
  }
  return (
    <>
    <div className='main'>
      <div>
        <div className='logo_login'>
          <img src={logoCreditBureau} alt="logo" className='imageLogo' />
        </div>
        <div className='card-login card'>
          <img src={logo} alt="logo" className='imageLogo' />
          <div  className="login_form">
            <Messages ref={msgs} />
            <h2>Solicitação de recuperação de senha</h2>
            
                <div className="flex-auto">
                    <label htmlFor="login" className="font-bold block mb-2 required">{t('inserirSenha')} </label>
                    <Password  onChange={(e) => setPassword(e.target.value)} style={{width: '20rem'}} feedback={false} toggleMask />
                </div>
                <br></br>
                <div className="flex-auto">
                    <label htmlFor="login" className="font-bold block mb-2 required">{t('repetirSenha')} </label>
                    <Password  onChange={(e) => setPasswordRepeat(e.target.value)} style={{width: '20rem'}} feedback={false} toggleMask />
                </div>
                
                <div style={{display:"flex", justifyContent:"end", paddingTop:"1rem"}} className=" flex ">
                 {show ? <Button style={{marginRight:"3rem", backgroundColor:'#183462'}} label="Redefinfir Senha"  onClick={submit} /> : <Button style={{color:'#183462'}}  outlined label="Voltar"  onClick={()=>{navigate("/")}} />} 
              </div> 
               
           
          </div>
        </div>
      </div>
    </div>
      
       
    </>
  )
}
