
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";




export default function Informativo({
  informativo
}: {
  informativo: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('filtroConsulta')} style={{width:'98%'}}>
       
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('usuario')}</label>
                 <Chip style={{width:"100%"}} label={informativo.usuario} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('data')}</label>
                 <Chip style={{width:"100%"}} label={informativo.data} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('documento')}</label>
                 <Chip style={{width:"100%"}} label={informativo.documento} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('protocolo')}</label>
                 <Chip style={{width:"100%"}} label={informativo.protocolo} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('opcoesConsulta')}</label>
                <div style={{width: '100%'}}>
                {informativo.opcoes.map((opcao:string) => {
                return (
                    <Chip key={opcao} style={{marginLeft: '0.5rem'}} label={opcao} />
                )})}
                </div>
            </div>
          </div>
          
          
      </div>
     
    </Panel>
  )
}
