
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from "primereact/card";




export default function IndicadorPJ({
  indicadores
}: {
  indicadores: any
}) {
  const { t } = useTranslation();
  const headerCard= (descricao:string) => {
    return <div className="flex-score align-items-center" style={{padding: '1.5rem'}}><div className="flex-score justify-content-center align-items-center p-2 border-round" style={{backgroundColor: '#183462'}}><i className="pi pi-chart-line" style={{color: "#FFF"}}></i></div><span className="text-xl ml-2 font-semibold">{descricao}</span></div>
  } 
  return (
    <Panel header={t('indicadores')} style={{width:'98%'}}>
       { indicadores.map((indicador:any) => {
        return (
          <>
            <Card className="flex-score flex-column" header={headerCard(indicador.descricao)} style={{color: '#183462', borderLeft: '4px solid', background:'rgb(248,248,255)'}}>
              <div className="grid mt-3">
                <div className="col-6 flex-score flex-column p-3 text-center border-right-1 surface-border">
                  
                  <span className="text-color font-semibold">{indicador.faixa}</span>
                </div>
                <div className="col-6 flex-score flex-column p-3 text-center ">
                  <span className="text-color text-2xl font-semibold">Conceito</span>
                  <span className="text-color font-semibold">{indicador.conceito}</span>
                </div>
              </div>
            </Card>
            <br></br>
          </>
        )
       }) }
       
    </Panel>
  )
}
