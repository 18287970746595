import { http } from '../../config/axios';

export default function getParametrosService(): Promise<any> {
  return http
    .get(`api/parametro-aplicacao/find-by-user`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('Erro ao buscar parâmetros', error);
      throw new Error(error.response?.data || 'Erro desconhecido ao buscar parâmetros');
    });
}
