import Topbar from "./Topbar";
import Sidebar from "./Sidebar";

const Interface = () => {
 
  return (
    <>
      <Topbar />
      <Sidebar />
    </>
  );
};

export default Interface;