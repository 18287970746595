import { useEffect, useState } from "react";

const usePopstate = () => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  const popChange = () => {
    setCurrentPath(window.location.pathname);
  };

  useEffect(() => {
    window.addEventListener("popstate", popChange);

    return () => {
      window.removeEventListener("popstate", popChange);
    };
  }, []);

  return { currentPath };
};

export default usePopstate;
