
interface Props  {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element),
    onClick : () => void
  }

const Button = ({children, onClick, ...props} : Props) => {
    return (
        <>
            <button  {...props} onClick={onClick}><>{children}</></button> 
        </>
    )
  }
export default Button;