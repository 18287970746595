
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Card} from "primereact/card";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';



export default function ListaConsulta({
  consulta
}: {
  consulta: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('listaConsulta')} style={{width:'98%'}}>
       
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <Card style={{backgroundColor: "rgba(255, 151, 0, 0.2)"}}>
              <span style={{fontSize:"0.8rem", fontWeight: "700", color:'rgb(255, 151, 0)'}}>{t('ultimos30Dias')}</span>
              <br></br>
              <span style={{fontSize:"0.8rem", fontWeight: "700", color:'rgb(255, 151, 0)'}}>{consulta.quantidade30Dias}</span>
             
            </Card>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <Card style={{backgroundColor: "rgba(126, 21, 227, 0.2)"}}>
              <span style={{fontSize:"0.8rem", fontWeight: "700", color: 'rgb(126, 21, 227)'}}>{t('de31a60Dias')}</span>
              <br></br>
              <span style={{fontSize:"0.8rem", fontWeight: "700", color: 'rgb(126, 21, 227)'}}>{consulta.quantidade31a60Dias}</span>
             
            </Card>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <Card style={{backgroundColor: "rgba(27, 87, 206, 0.2)"}}>
              <span style={{fontSize:"0.8rem", fontWeight: "700", color: 'rgb(27, 87, 206)'}}>{t('de61a90Dias')}</span>
              <br></br>
              <span style={{fontSize:"0.8rem", fontWeight: "700", color: 'rgb(27, 87, 206)'}}>{consulta.quantidade61a90Dias}</span>
             
            </Card>
          </div>
          <div className="col p-fluid flex flex-wrap">
            <Card style={{backgroundColor: "rgba(31, 166, 133, 0.2)"}}>
              <span style={{fontSize:"0.8rem", fontWeight: "700", color: 'rgb(31, 166, 133)'}}>{t('acima90Dias')}</span>
              <br></br>
              <span style={{fontSize:"0.8rem", fontWeight: "700", color: 'rgb(31, 166, 133)'}}>{consulta.quantidadeMais90Dias}</span>
            </Card>
          </div>
      </div>

      <br></br>
      <DataTable size="small" value={consulta.consultas} tableStyle={{ minWidth: '50rem' }}>
          <Column field="segmento" header={t('segmento')}></Column>
          <Column field="quantidade" header={t('quantidade')}></Column>
          <Column field="data" header={t('data')}></Column>
      </DataTable>
    </Panel>
  )
}
