import { merge } from "lodash";

const Divider = ({ style = {} }) => {
  const defaultStyle = {
    border: "none",
    backgroundColor: "rgba(0,0,0,0.16)",
    height: "1px",
    margin: "16px 0",
  };

  const mergedStyle = merge(defaultStyle, style);

  return <hr style={mergedStyle} />;
};

export default Divider; 
