import { http } from '../../config/axios'

export default function postCancelarRegistro(registro: any): Promise<string> {
  return http
    .post('api/registro/cancelar', registro)
    .then((res) => {
      return res.data as string
    })
    .catch((error) => {
      console.error('Erro post cancelar registro', error)
      return 'Erro ' + error.response.data
    })
}
