import { http } from '../../config/axios'

export default function getArquivoRegistroDownload(id:string, encriptado:boolean) :Promise<Blob>{
    return http.get('/api/arquivoregistro/download/' + id + '/' + encriptado, {
        responseType: 'blob'
    })
    .then((r) => r.data)
    .then((blobFile) => {
        return blobFile
    })
    .catch((error) => {
        console.error('Erro ao baicar download getArquivoRegistroDownload')
        return new Blob()
    })
}