  
  import {MenuItemInterface} from "../interface/MenuItemInterface"

  const menuItems : MenuItemInterface[] = [
    {
      label: "home",
      icon: 'pi-home',
      url: "/home",
      permission: "HOME",
      hasDivider: false
    },
    {
      label: "consultaCredito",
      icon: 'pi-search',
      url: "/consultar",
      permission: "CONSULTAR",
      hasDivider: false
    },
    {
      label: "registro",
      icon: 'pi-id-card',
      url: "/registro",
      permission: "REGISTRO_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "historicoConsulta",
      icon: ' pi-history',
      url: "/historico",
      permission: "CONSULTA_VISUALIZAR",
      hasDivider: true
    },
    {
      label: "arquivoRegistro",
      icon: 'pi-folder',
      url: "/arquivoregistro",
      permission: "ARQUIVOREGISTRO_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "relatorioSinteticoDeConsultas",
      icon: ' pi-filter',
      url: "/filtrarconsultasintetica",
      permission: "RELATORIO_CONSULTA_SINTETICA",
      hasDivider: false
    },
    {
      label: "relatorioApuracaoBilhete",
      icon: ' pi-filter',
      url: "/filtrarapuracaobilhete",
      permission: "RELATORIO_APURACAO_BILHETE",
      hasDivider: false
    },
    {
      label: "usuario",
      icon: 'pi-users',
      url: "/usuario",
      permission: "USUARIO_VISUALIZAR",
      hasDivider: true
    },
    {
      label: "cliente",
      icon: 'pi-tag',
      url: "/cliente",
      permission: "CLIENTE_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "permissao",
      icon: 'pi-code',
      url: "/permissao",
      permission: "PERMISSAO_VISUALIZAR",
      hasDivider: false
    },
    {
      label: "parametrosAplicacao",
      icon: 'pi-cog',
      url: "/parametros",
      permission: "PARAMETRO_VISUALIZAR",
      hasDivider: false
    }
    
  ];
 
  export default menuItems;
  