
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import Highcharts from 'highcharts';
import PieChart from "highcharts-react-official";


export default function Pie({
  series,
  title
}: {
    series: any,
    title:string
}) {
  const { t } = useTranslation();
 

  
  const options = {
    chart: {
      type: "pie"
    },
    title: {
      text: title
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>'
    },
    credits: {
      enabled: false
    },
    series: [
        {
          data: series
        }
    ]
  };
  
  return (
    <PieChart highcharts={Highcharts} options={options} />
  )
}
