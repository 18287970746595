import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import getLoginSSO from '../../service/getLoginSSO'
import { GlobalSaveData } from "../../components/globalState/GlobalSaveData";
import getRelatorioNotificacao from '../../service/getRelatorioNotificacao'
import getUser from '../../service/getUser'


export default function LoginSSOPage() {
  const { jwt } = useParams()
  const initialized = useRef(false)
  
  const submit = (jwt:any) => {
    

    setTimeout(() =>{
      getLoginSSO(jwt)
              .then((data) => {
                GlobalSaveData("token", data)
                setTimeout(() =>{
                  getRelatorioNotificacao().then((data) => {
                    GlobalSaveData("relatorios", data);
                  });
                },100);
                
                setTimeout(() =>{
                  getUser().then((user) => {
                    GlobalSaveData("user", user);
                    setTimeout(() =>{window.location.href= (user.urlRedirect && user.urlRedirect !== 'null'? user.urlRedirect : '/creditbureau/home')},100);
                  }).catch((error) => {
                    window.location.href='/creditbureau/login';
                  })
                },100);
                
              })
              .catch((error) => {
                window.location.href='/creditbureau/login';
              })
            },1000);
  }
  
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      submit(jwt);
    }
  },[])
  
  return (
    <>
    
    </>
  )
}
