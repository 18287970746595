
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function Empresas({
  empresas
}: {
  empresas: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('participacaoEmpresa')} style={{width:'98%'}}>
       
       <DataTable size="small" value={empresas} tableStyle={{ minWidth: '50rem' }}>
          <Column field="cnpj" header={t('cnpj')}></Column>
          <Column field="status" header={t('status')}></Column>
          <Column field="nome" header={t('razaoSocial')}></Column>
          <Column field="tipo" header={t('tipo')}></Column>
          <Column field="participacao" header={t('participacao')}></Column>
          <Column field="valorParticipacao" header={t('valorParticipacao')}></Column>
          <Column field="nivelConfianca" header={t('nivelConfianca')}></Column>
          <Column field="trabalhoPrincipal" header={t('trabalhoPrincipal')}></Column>
          <Column field="dataInicio" header={t('dataEntrada')}></Column>
          <Column field="dataAtualizacao" header={t('dataAtualizacao')}></Column>
      </DataTable>
    </Panel>
  )
}
