
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";




export default function CompletaPJ({
  completaPJ
}: {
  completaPJ: any
}) {
  const { t } = useTranslation();
  const stringBodyTemplate = (item:string) => {
    return item;
  };
  return (
    <Panel header={t('completaPJ')} style={{width:'98%'}}>
       
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('razaoSocial')}</label>
                 <Chip style={{width:"100%"}} label={completaPJ.razaoSocial} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('nomeFantasia')}</label>
                 <Chip style={{width:"100%"}} label={completaPJ.nomeFantasia ? completaPJ.nomeFantasia : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('documento')}</label>
                 <Chip style={{width:"100%"}} label={completaPJ.cnpj} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('dataAbertura')}</label>
                 <Chip style={{width:"100%"}} label={completaPJ.dataAbertura} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('situacao')}</label>
                 <Chip style={{width:"100%"}} label={completaPJ.situacaoCadastral} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('cnae')}</label>
                 <Chip style={{width:"100%"}} label={completaPJ.cnaePrincipal + ' - ' + completaPJ.descricaoCnaePrincipal } />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('naturezaJuridica')}</label>
                 <Chip style={{width:"100%"}} label={completaPJ.naturezaJuridica + ' - ' + completaPJ.descricaoNaturezaJuridica} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('capitalSocial')}</label>
                 <Chip style={{width:"100%"}} label={completaPJ.capitalSocial ? completaPJ.capitalSocial  : 'Não Informado' } />
            </div>
          </div>
      </div>
      

      {completaPJ.enderecos ? (
        <>
          <br></br>
          <Panel header={t('enderecos')}>
            <DataTable size="small" value={completaPJ.enderecos} tableStyle={{ minWidth: '50rem' }}>
              <Column field="logradouro" header={t('logradouro')}></Column>
              <Column field="cep" header={t('cep')}></Column>
              <Column field="bairro" header={t('bairro')}></Column>
              <Column field="cidade" header={t('cidade')}></Column>
              <Column field="uf" header={t('uf')}></Column>
              <Column field="complemento" header={t('complemento')}></Column>
              <Column field="numero" header={t('numero')}></Column>
            </DataTable>
          </Panel>
        </>
      ):''}

      {completaPJ.socios ? (
        <>
          <br></br>
          <Panel header={t('socios')}>
            <DataTable size="small" value={completaPJ.socios} tableStyle={{ minWidth: '50rem' }}>
              <Column field="documento" header={t('documento')}></Column>
              <Column field="nome" header={t('nome')}></Column>
              <Column field="cargo" header={t('cargo')}></Column>
              <Column field="participacao" header={t('participacao')}></Column>
              <Column field="dataEntrada" header={t('dataEntrada')}></Column>
            </DataTable>
          </Panel>
        </>
      ):''}

      {completaPJ.cnaes ? (
        <>
          <br></br>
          <Panel header={t('cnaes')}>
            <DataTable size="small" value={completaPJ.cnaes} tableStyle={{ minWidth: '50rem' }}>
              <Column field="codigo" header={t('codigo')}></Column>
              <Column field="descricao" header={t('descricao')}></Column>
            </DataTable>
          </Panel>
        </>
      ):''}

      {completaPJ.telefones ? (
        <>
          <br/>
          <Panel header={t('telefones')}>
            <DataTable size="small" value={completaPJ.telefones} tableStyle={{ minWidth: '50rem' }}>
              <Column field="numero" body={stringBodyTemplate} header={t('fone')}></Column>
            </DataTable>
          </Panel>
        </>
      ):''}

      {completaPJ.emails ? (
        <>
          <br/>
          <Panel header={t('emails')}>
            <DataTable size="small" value={completaPJ.emails} tableStyle={{ minWidth: '50rem' }}>
              <Column field="numero" body={stringBodyTemplate} header={t('email')}></Column>
            </DataTable>
          </Panel>
        </>
      ):''} 

      
      
    </Panel>
  )
}
