import { http } from '../../config/axios'

export default function getViaCep(cep:string): Promise<any> {
  return http
    .get('https://viacep.com.br/ws/'+cep+'/json')
    .then((res) => {
      return res.data as any
    })
    .catch((error) => {
      console.error('Erro getViaCep', error)
      throw Error(error.response.data)
    })
}
