
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";




export default function CompletaPF({
  completaPF
}: {
  completaPF: any
}) {
  const { t } = useTranslation();
  const stringBodyTemplate = (item:string) => {
    return item;
  };
  return (
    <Panel header={t('completaPF')} style={{width:'98%'}}>
       
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('nome')}</label>
                 <Chip style={{width:"100%"}} label={completaPF.nome} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('sexo')}</label>
                 <Chip style={{width:"100%"}} label={completaPF.sexo} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('documento')}</label>
                 <Chip style={{width:"100%"}} label={completaPF.cpf} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('dataNascimento')}</label>
                 <Chip style={{width:"100%"}} label={completaPF.dataNascimento} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('nomeMae')}</label>
                 <Chip style={{width:"100%"}} label={completaPF.nomeMae} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('nomePai')}</label>
                 <Chip style={{width:"100%"}} label={completaPF.nomePai ? completaPF.nomePai  : 'Não Informado' } />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('situacao')}</label>
                 <Chip style={{width:"100%"}} label={completaPF.situacaoRfb.situacao} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('escolaridade')}</label>
                 <Chip style={{width:"100%"}} label={completaPF.escolaridade ? completaPF.escolaridade  : 'Não Informado' } />
            </div>
          </div>
      </div>
      {completaPF.rendaPresumida ? (
        <>
          <br></br>
          <Panel header={t('rendaPresumida')}>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('faixa')}</label>
                    <Chip style={{width:"100%"}} label={completaPF.rendaPresumida.faixaRenda} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('origem')}</label>
                    <Chip style={{width:"100%"}} label={completaPF.rendaPresumida.origemRenda ? completaPF.rendaPresumida.origemRenda : 'Não Informado'} />
                </div>
              </div>
            </div>
          </Panel>
        </>
      ):''}

      {completaPF.enderecos ? (
        <>
          <br></br>
          <Panel header={t('enderecos')}>
            <DataTable size="small" value={completaPF.enderecos} tableStyle={{ minWidth: '50rem' }}>
              <Column field="logradouro" header={t('logradouro')}></Column>
              <Column field="cep" header={t('cep')}></Column>
              <Column field="bairro" header={t('bairro')}></Column>
              <Column field="cidade" header={t('cidade')}></Column>
              <Column field="uf" header={t('uf')}></Column>
              <Column field="complemento" header={t('complemento')}></Column>
              <Column field="numero" header={t('numero')}></Column>
            </DataTable>
          </Panel>
        </>
      ):''}

      {completaPF.telefones ? (
        <>
          <br/>
          <Panel header={t('telefones')}>
            <DataTable size="small" value={completaPF.telefones} tableStyle={{ minWidth: '50rem' }}>
              <Column field="tipo" header={t('tipo')}></Column>
              <Column field="numero" header={t('numero')}></Column>
            </DataTable>
          </Panel>
        </>
      ):''}

      {completaPF.emails ? (
        <>
          <br/>
          <Panel header={t('emails')}>
            <DataTable size="small" value={completaPF.emails} tableStyle={{ minWidth: '50rem' }}>
              <Column field="numero" body={stringBodyTemplate} header={t('email')}></Column>
            </DataTable>
          </Panel>
        </>
      ):''} 

      {completaPF.participacoes ? (
        <>
          <br/>
          <Panel header={t('empresas')}>
            <DataTable size="small" emptyMessage="Nenhum Registro" value={completaPF.participacoes} tableStyle={{ minWidth: '50rem' }}>
              <Column field="cnpj" header={t('cnpj')}></Column>
              <Column field="razaoSocial" header={t('razaoSocial')}></Column>
              <Column field="cargo" header={t('tipo')}></Column>
              <Column field="participacao" header={t('participacao')}></Column>
              <Column field="dataEntrada" header={t('dataEntrada')}></Column>
            </DataTable>
          </Panel>
        </>
      ):''}
      
    </Panel>
  )
}
