import { http } from '../../config/axios'

export default function postRecuperarSenha(email: String): Promise<string> {


var config = {
    headers: {
        'Content-Length': 0,
        'Content-Type': 'text/plain'
    },
   responseType: 'text'
};
  return http
    .post('api/gatewaybiro/recuperation', email, {
      headers: {
        "Content-Type":"text/plain"
      }
  })
    .then((res) => {
      return res.data as string
    })
    .catch((error) => {
      console.error('Erro ao Recuperar Senha', error)
      throw Error(error.response.data)
    })
}
