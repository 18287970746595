import { http } from '../../config/axios'

export default function postParametrosService(parametro: any): Promise<string> {
  return http
    .post('api/parametro-aplicacao', parametro)
    .then((res) => {
      return res.data as string;
    })
    .catch((error) => {
      throw Error(error.response.data);
    });
};
