import { useTranslation } from "react-i18next";
import {Tooltip } from 'react-tooltip'
import Divider from "./Divider";
import {MenuItemInterface} from "../../interface/MenuItemInterface"
import { useNavigate } from "react-router-dom";
import {IconType } from '@react-icons/all-files'
import { useGlobalState } from "../globalState/GlobalStateProvider";
type Props = {
  isActive: boolean,
  item: MenuItemInterface
}
 
const MenuItem = ({ isActive, item }: Props) => {
  const { t } = useTranslation();
  const stateGlobal = useGlobalState();
  const navigate = useNavigate();
  const handleNavigate = (url:string) => (event:any) => {
    event.preventDefault();
    navigate(url);
  };
  type PropsWrapper = {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
  }
  const Wrapper = ({children} : PropsWrapper) => {
   
    if (!!stateGlobal?.state.sidebarOpen) return <>{children}</>;
    return (
      <>
        <a  className={isActive ? "active" : ""} href={item.url} onClick={handleNavigate(item.url)} data-tooltip-id="my-tooltip" data-tooltip-content={t(item.label)}><span className={'icon pi ' + item.icon}></span></a>
        <Tooltip positionStrategy="fixed" id="my-tooltip" place="right"  children={children}/>
      </>
    );
  };
 
  const createIcon = (Icon : IconType) => { return (<div> <Icon></Icon> </div>) };
 
  return (
    <>
      {!!item.hasDivider && <Divider style={{ margin: 0 }} />}
      <li>
        <Wrapper>
          <a
            href={item.url}
            onClick={handleNavigate(item.url)}
            className={isActive ? "active" : ""}
          >
            <span className={'icon pi ' + item.icon}></span>
            <span className="label">{t(item.label)}</span>
          </a>
        </Wrapper>
      </li>
    </>
  );
};
 
export default MenuItem;