
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function EnderecoHist({
  enderecos
}: {
    enderecos: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('historicoEndereco')} style={{width:'98%'}}>
       
       <DataTable size="small" value={enderecos} tableStyle={{ minWidth: '50rem' }}>
          <Column field="logradouro" header={t('logradouro')}></Column>
          <Column field="cep" header={t('cep')}></Column>
          <Column field="bairro" header={t('bairro')}></Column>
          <Column field="cidade" header={t('cidade')}></Column>
          <Column field="uf" header={t('uf')}></Column>
          <Column field="complemento" header={t('complemento')}></Column>
          <Column field="numero" header={t('numero')}></Column>
          <Column field="data" header={t('data')}></Column>
      </DataTable>
    </Panel>
  )
}
