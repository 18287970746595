import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import ParametrosAplicacaoCadastrar from './ParametrosAplicacaoCadastrar';
import { Messages } from 'primereact/messages';
import postParametros from "../../service/postParametros";
import putParametros from "../../service/putParametros";
import getParametros from "../../service/getParametros"; 
import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";

export default function ParametrosAplicacaoPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const [parametros, setParametros] = useState<any | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const labelButton = t('cadastrar') + ' / ' + t('editar');

  const fetchParametros = () => {
    getParametros().then((data: any) => {
      if (data) {
        setParametros(data);
        setIsEditing(true);
      }
    }).catch((err: any) => {
      console.error('Erro ao buscar parâmetros:', err);
    });
  };

  const saveParametros = (parametro: any) => {
    if (parametro.id) {
      putParametros(parametro).then((data: any) => {
        if (data.includes('Erro')) {
          msgs.current?.clear();
          msgs.current?.show([
            { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
          ]);
        } else {
          setParametros(null);
          msgs.current?.clear();
          msgs.current?.show([
            { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
          ]);
        }
      });
    } else {
      postParametros(parametro).then((data: any) => {
        if (data.includes('Erro')) {
          msgs.current?.clear();
          msgs.current?.show([
            { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
          ]);
        } else {
          setParametros(null);
          msgs.current?.clear();
          msgs.current?.show([
            { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
          ]);
        }
      });
    }
  };

  const startContent = (
    <React.Fragment>
      <Button label={labelButton} icon="pi pi-plus" style={{ backgroundColor: '#183462' }} onClick={() => { 
        setParametros({}); 
        fetchParametros();
      }} />
    </React.Fragment>
  );

  return (
    <Container>
      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray">
              <i className="pi pi-cog" style={{ fontSize: '2.0rem' }}></i> {t('parametrosAplicacao')}
            </h1>
          </div>
        </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!parametros && (
          <>
            <Toolbar start={startContent} />
            <br />
            <br />
          </>
        )}
        {parametros && (
          <>
            <ParametrosAplicacaoCadastrar
              saveParametros={saveParametros}
              parametros={parametros}  
              isEditing={isEditing}  
            />
          </>
        )}
      </div>
    </Container>
  );
}
