
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import Highcharts from 'highcharts';
import bullet from "highcharts/modules/bullet.js";
import HighchartsReact from 'highcharts-react-official';

bullet(Highcharts);

export default function ScoreGrauAtvidadePJ({
  scoreGrauAtividadePJ
}: {
  scoreGrauAtividadePJ: any
}) {
  const { t } = useTranslation();
 
  const options = {
    
    chart: {
        inverted: true,
        type: 'bullet',
        height: 100
    },
    title: {
        text: null
    },
    legend: {
        enabled: false
    },
   
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },


    xAxis: {
        categories: ['']
    },
    tooltip:{
      enabled: false   
    },
    yAxis: {
      gridLineWidth: 0,
      max:100,
      plotBands: [{
          from: 0,
          to: 40,
          color: '#FA8072'
      }, {
          from: 40,
          to: 65,
          color: '#FFD700'
      }, {
          from: 65,
          to: 100,
          color: '#90EE90'
      }],
      title: null
  },
    series: [{
        data: [{
            y: parseInt(scoreGrauAtividadePJ.score),
            target: parseInt(scoreGrauAtividadePJ.score) -1,
            targetOptions:{
              color:'#F5F5DC'
            }
        }]
    }]


};
  
  const optionsPrint = {
    
      chart: {
          inverted: true,
          type: 'bullet',
          height: 100,
          width: 750
      },
      title: {
          text: null
      },
      legend: {
          enabled: false
      },
     
      credits: {
          enabled: false
      },
      exporting: {
          enabled: false
      },
  
  
      xAxis: {
          categories: ['']
      },
      tooltip:{
        enabled: false   
      },
      yAxis: {
        gridLineWidth: 0,
        max:100,
        plotBands: [{
            from: 0,
            to: 40,
            color: '#FA8072'
        }, {
            from: 40,
            to: 65,
            color: '#FFD700'
        }, {
            from: 65,
            to: 100,
            color: '#90EE90'
        }],
        title: null
    },
      series: [{
          data: [{
              y: parseInt(scoreGrauAtividadePJ.score),
              target: parseInt(scoreGrauAtividadePJ.score) -1,
              targetOptions:{
                color:'#F5F5DC'
              }
          }]
      }]
  
  
  };
  return (
    <Panel header={t('grauAtividadePJ')} style={{width:'98%'}}>
       
       
      
      
       <div className="div-renda">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
          <div className="div-renda-print">
            <HighchartsReact highcharts={Highcharts} options={optionsPrint} />
          </div>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          {scoreGrauAtividadePJ.rating}
        </div>
      </div>
    </Panel>
  )
}
