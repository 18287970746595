import { Dispatch,  SetStateAction} from 'react'
import { GlobalStateInterface, useGlobalState } from './GlobalStateProvider';


  let context: { state: Partial<GlobalStateInterface>; setState: Dispatch<Partial<GlobalStateInterface>>; };
  export default function GlobalState() {
    context =  useGlobalState();
    
    return (
      <>
        <div></div>
      </>
    )
  }
const GlobalSaveData = (atribut:string, value:any) => {
    const { state, setState } =  context;
    switch (atribut) {
      case 'sidebarOpen':
        state.sidebarOpen=value;
        setState({...state, sidebarOpen: value});
        break;
      case 'token':
        state.token=value;
        setState({...state, token: value});
        
        localStorage.setItem('@QG_TOKEN',value);
        break;
      case 'user':
        state.user=value;
        setState({...state, user: value});
        
        break;
      case 'relatorios':
          state.relatorios=value;
          setState({...state, relatorios: value});
          
          break;
     
      default:
        console.log('Sorry, we are out of');
    }
    
    
    
  }
export { GlobalSaveData, GlobalState};