import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Tree, TreeCheckboxSelectionKeys, TreeMultipleSelectionKeys } from 'primereact/tree';
import { useGlobalState } from '../../../components/globalState/GlobalStateProvider';
import { Divider } from 'primereact/divider';
import { FilterWeb } from '../../../interface/FilterWeb';
import { Fieldset } from 'primereact/fieldset';
import { PickList } from 'primereact/picklist';


export default function FiltroPF({
    onProcess,
    onClose,
    msgs
  }: {
    onProcess: (filterWeb : FilterWeb) => void
    onClose: () => void,
    msgs:any
  }) {
  const { t } = useTranslation();
  const {state} = useGlobalState();
  const [cpf, setCpf] = useState<string>("");
  const [selectedKeys, setSelectedKeys] = useState<string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | null>(null); 
  const [selectedOptionCheck, setSelectedOptionCheck] = useState<any[]>([]);
  const [targets, setTargets] = useState<any[]>([]);


  const validCPF = (documento:string) => {
    if(verifyCPF(documento)){
      msgs.current?.clear();
      
    }
    else{
      window.scrollTo(0, 0);
      msgs.current?.clear();
        msgs.current?.show([
            { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: 'Documento inválido', closable: false }
          
        ]);
    }

  }

  const  verifyCPF = (strCPF:string) => {
    strCPF = strCPF.replaceAll('.','').replace('-','')
    let Soma;
    let Resto;
    Soma = 0;
  if (strCPF == "00000000000") return false;
  if (strCPF == "11111111111") return false;
  if (strCPF == "22222222222") return false;
  if (strCPF == "33333333333") return false;
  if (strCPF == "44444444444") return false;
  if (strCPF == "55555555555") return false;
  if (strCPF == "66666666666") return false;
  if (strCPF == "77777777777") return false;
  if (strCPF == "88888888888") return false;
  if (strCPF == "99999999999") return false;

  for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

  Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}

const expandNode = (node:any, _expandedKeys:any) => {
  if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
          expandNode(child, _expandedKeys);
      }
  }
};

const createOptions = (optionElement : any) =>{
  const option : any[] = [];

  optionElement.permissoes.filter((f: {
    tipoConsulta: string; 
  }) => f.tipoConsulta ===  'PF' || f.tipoConsulta === 'PFPJ')
  .sort( (a:any,b:any) => {
    {
      if(!a.ordenacao){
        return 1;
      }

      if(!b.ordenacao){
        return -1;
      }

      if (a.ordenacao > b.ordenacao) {
        return 1;
      }
      if (a.ordenacao < b.ordenacao) {
        return -1;
      }
      return 0;
    }
  } )
  .map((element: any) => {
     
      option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes ? createPermissoesFilhos(element.permissoes.filter((f: {
        tipoConsulta: string; 
      }) => f.tipoConsulta ===  'PF' || f.tipoConsulta === 'PFPJ')) : []});
          });
    
  

          expandAll(option)      
  return option;
}

const createPermissoesFilhos = (permissoes:any[]) => {
  const option : any[] = [];
  if(permissoes){
    permissoes
    .sort( (a:any,b:any) => {
      {
        if(!a.ordenacao){
          return 1;
        }

        if(!b.ordenacao){
          return -1;
        }

        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        return 0;
      }
    } )
    .forEach((element: {
      children: any; label: string; value: string; permissoes:any[]  
    }) => {
      if(element.value == 'QUODPFINDICADORESNEGOCIO_PERFIL' || 
        element.value == 'QUODPFINDICADORESNEGOCIO_MERCADO' ||
        element.value == 'QUODPFINDICADORESNEGOCIO_GESTAO' ||
        element.value == 'QUODPFINDICADORESNEGOCIO_CENARIO' ||
        element.value == 'QUODPFINDICADORESNEGOCIO_PONTUALIDADE'){
        if(targets.filter((s)=>s.value===element.value).length === 0){
          targets.push({value : element.value, target : []})
        }
        
      }
      option.push({label:element.label, key:element.value, Collapse:true, pickList: targets.filter((s) => s.value === element.value).length != 0 ? element.permissoes : undefined, children: element.permissoes && targets.filter((s) => s.value === element.value).length === 0 ? createPermissoesFilhos(element.permissoes.filter((f: {
        tipoConsulta: string; 
      }) => f.tipoConsulta === 'PF' || f.tipoConsulta === 'PFPJ')) : []});
    });
  }
  return option;
}

const expandAll = (option:any) => {
  let _expandedKeys = {};

  for (let node of option) {
      expandNode(node, _expandedKeys);
  }

 return _expandedKeys;
};

  const processConsulta = () => {

    const opcoes: string[] = [];
    if(selectedKeys){
      Object.getOwnPropertyNames(selectedKeys).map((item) => opcoes.push(item));
    }
    selectedOptionCheck.map((item) => opcoes.push(item.value));
    const filteWeb :FilterWeb = {
      documento: cpf,
      consultas: opcoes,
      valor: null,
      arrojado: null,
      usual: null,
      conservador: null,
      indicadores: targets.filter((s:any) => opcoes.includes(s.value))
    };
    onProcess(filteWeb);
  }
  const onChange = (event:any, key:string) => {
    const targetsOut = [{value: key, target: event.target}]
    targets.filter((s:any) => s.value !== key).map((item:any) => targetsOut.push(item));
    setTargets(targetsOut)
};
  const pickItemTemplate = (item:any) => {
    return (
        <div className="flex flex-wrap p-2 align-items-center gap-3">
            <div className="flex-1 flex flex-column gap-2">
                <span className="font-bold">{item.label}</span>
            </div>
        </div>
    );
};
  const nodeTemplate = (node: any, options: { className: string | undefined; }) => {
    let label = <span>{node.label}</span>;
    if (node.children.length !== 0) {
       label = <b>{node.label}</b>;
    }

    if((node.pickList && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes(node.key))){
      console.log(node);
      return <>
              <Fieldset onClick={(e)=> e.stopPropagation()} legend={node.label}>
                <PickList dataKey="value" onChange={(e) => {onChange(e, node.key)}} itemTemplate={pickItemTemplate} showSourceControls={false} showTargetControls={false} source={node.pickList.filter((s:any) => {return targets.filter((s:any) => s.value === node.key)[0].target.filter((s2:any) => s.value === s2.value).length == 0} )} target={targets.filter((s:any) => s.value === node.key)[0].target}  filter filterBy="label" 
                  sourceHeader="Indicadores" targetHeader="Selecionados" sourceStyle={{ height: '24rem' }} targetStyle={{ height: '24rem' }}
                  sourceFilterPlaceholder="Busca" targetFilterPlaceholder="Busca" />
              </Fieldset>
              </>   
    }

    return label
  }

  return (<>
    <Panel header={t('consultaPF')}>
        <div className=" p-fluid flex flex-wrap gap-3">
            <div className="flex-auto">
                <label htmlFor="cpf" className="font-bold block mb-2 required">CPF</label>
                <InputMask id="cpf" value='cpf' onBlur={(e)=>{setCpf(e.target.value);validCPF(e.target.value)}} mask="999.999.999-99" placeholder="999.999.999-99" required></InputMask>
            </div>
            <br></br>
            {state.user.consultas.filter((f: { tipoConsulta: string; }) => f.tipoConsulta === 'PF' || f.tipoConsulta === 'PFPJ').sort( (a:any,b:any) => {
      {
        if(!a.ordenacao){
          return 1;
        }

        if(!b.ordenacao){
          return -1;
        }

        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        return 0;
      }
    } ).map((_item: any) => {
                    return ( <div key={_item.value+'_main'}>
                        <b>{_item.label}</b>
                        <div key={_item.value} className="flex flex-column gap-3">
                            <br></br>
                            <Tree key={_item.value+'_tree'} nodeTemplate={nodeTemplate} expandIcon={undefined} collapseIcon={undefined} expandedKeys = {expandAll(createOptions(_item))} value={createOptions(_item)} selectionMode="checkbox" selectionKeys={selectedKeys} onSelectionChange={(e) => {setSelectedKeys(e.value);}} style={{border:'none', marginLeft:'-2.0rem', background:'transparent'}}  />
                        </div>
                        <br></br>
                        <Divider />
                        <br></br>
                        </div>);
                })}
         
        </div>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex ">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Consultar"  onClick={processConsulta} />
            <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
        </div> 
            
    </Panel>
   
    </>
  )
}
