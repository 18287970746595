import { http } from '../../config/axios'

export default function getRelatorioNotificacao() :Promise<String>{
    return http.get('/api/relatorio/notificacao', {
        headers: {
            'ignoreLoading': 'true'
        }
    })
    .then((r) => r.data === "0" ? "" : r.data as String)
    
    .catch((error) => {
        console.error('Erro ao baixar download relatório')
        return ""
    })
}