
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import segmento from "../../../assets/score_pj_boleto.jpeg";



export default function Score({
  label,
  score,
  scoreBoleto
}: {
  label: string,
  score: any,
  scoreBoleto: boolean
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const riscoAltissimo = '#DE1616';
	const riscoMuitoAlto = '#DE1616';
	const riscoAlto = '#FF9700';
	const riscoMedio = '#ffca39';
	const riscoBaixo = '#0DAB01';
	const riscoMinimo = '#1FA685';
  const headerMercado = () => {
    return <div className="flex-score align-items-center" style={{padding: '1.5rem'}}><div className="flex-score justify-content-center align-items-center p-2 border-round" style={{backgroundColor: 'rgb(100, 181, 246)'}}><i className="pi pi-shopping-cart" style={{color: "rgb(25, 118, 210)"}}></i></div><span className="text-xl ml-2 font-semibold">Perfil de Mercado</span></div>
  } 
  const footerContent = (
    <div>
        <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => setVisible(false)} className="p-button-text" />
    </div>
);
const headerCard= () => {
  return <div className="flex-score align-items-center" style={{padding: '1.5rem'}}><div className="flex-score justify-content-center align-items-center p-2 border-round" style={{backgroundColor: '#6219B6'}}><i className="pi pi-info" style={{color: "#FFF"}}></i></div><span className="text-xl ml-2 font-semibold">Motivo</span></div>
} 
  const headerPontualidade = () => {
    return <div className="flex-score align-items-center" style={{padding: '1.5rem'}}><div className="flex-score justify-content-center align-items-center p-2 border-round" style={{backgroundColor: 'rgb(77, 182, 172)'}}><i className="pi pi-dollar" style={{color: "rgb(0, 121, 107)"}}></i></div><span className="text-xl ml-2 font-semibold">Pontualidade de Pagamento</span></div>
  } 

	let labelColor = riscoAlto;
	if (score.mensagemRestritiva.toUpperCase() === 'ALTÍSSIMO') {
		labelColor = riscoAltissimo;
	} else if (score.mensagemRestritiva.toUpperCase() === 'MUITO ALTO') {
		labelColor = riscoMuitoAlto;
	} else if (score.mensagemRestritiva.toUpperCase() === 'ALTO') {
		labelColor = riscoAlto;
	} else if (score.mensagemRestritiva.toUpperCase() === 'MEDIO' || score.mensagemRestritiva.toUpperCase() === "MÉDIO") {
		labelColor = riscoMedio;
	} else if (score.mensagemRestritiva.toUpperCase() === 'BAIXO') {
		labelColor = riscoBaixo;
	} else if (score.mensagemRestritiva.toUpperCase() === 'MÍNIMO' || score.mensagemRestritiva.toUpperCase() === 'MUITO BAIXO') {
		labelColor = riscoMinimo;
	}
  const options = {
    chart: {},
        title: {
            text: score.score + '',
            align: 'center',
            verticalAlign: 'middle',
            y: 90,
            style: {
              fontSize: '40px',
              color: labelColor
           }
        },
        credits: {
            enabled: false
        },
        tooltip:{
          enabled: false   
        },
        plotOptions: {
            pie: {
              allowPointSelect: false,
                dataLabels: {
                    enabled: false,
                    distance: -50,
                    
                    style: {
                        fontWeight: 'bold',
                        color: 'white',
                    }
                },
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '75%']
            }
        },
        
        series: [{
            type: 'pie',
            name: '',
            innerSize: '60%',
            data: [{
                name: '',
                y: score.score,
                color: labelColor,
                selected: true,
               showInLegend : false
            }, {
                name: '',
                y: 1000 - score.score,
                color: '#ccc',
                selected: true,
                showInLegend : false,
                
            }]
        }]
    
  };
  return (
    <>
    <Panel header={label} style={{width:'98%'}}>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
          </div>
          <div className="col">
           
            <div style={{textAlign:'center', paddingTop:'5rem'}}>
              {score.segmento && (
                <>
                  <span style={{color:'#5a5c69', fontWeight:'700', fontSize:'1.25rem'}}>{t('motivoScore')}</span>
                  <br></br>
                  <span style={{color:'#5a5c69', fontWeight:'500', fontSize:'1.0rem'}}>{score.segmento}</span>
                  <br></br>
                  <br></br>
                </>
              )}
              
              <span style={{color:'#5a5c69', fontWeight:'700', fontSize:'1.25rem'}}>{t('riscoCredito')}</span>
              <br></br>
              <span style={{color:labelColor, fontWeight:'700', fontSize:'1.5rem'}}>{score.mensagemRestritiva}</span>
              <br></br>
              <br></br>
              <span style={{color:'#5a5c69', fontWeight:'700', fontSize:'1.25rem'}}>{t('probabilidadePagamento')}</span>
              <br></br>
              <span style={{color:labelColor, fontWeight:'700', fontSize:'1.5rem'}}>{score.mensagemInformativa}</span>
              <br></br>
              <br></br>
              <Button className="button_score" label="Entenda o Score" icon="pi pi-question-circle" style={{backgroundColor:'#183462', border: 'none'}} onClick={() => setVisible(true)} />
              <Dialog header="Entenda o Score" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
              {scoreBoleto ? 
              
              <>
                <p>
                  O Quod Score PJ com boletos tem como objetivo avaliar o risco de crédito das Empresas por meio de pontuações que variam <b>entre 300 e 1000</b>, sendo quanto mais alta, menor a chance de inadimplência nos próximos 6 meses e inversamente proporcional. 
                </p>
                <h4 className="m-0 font-weight-bold text-success">Principais características da solução</h4>
                <p>Solução foi desenvolvida com o objetivo de atingir a melhor performance para cada tipo e perfil de cliente, na sua construção os seguintes dados podem ser utilizados com intuito de atingir esta melhor performance e assertividade <b>*</b></p>
                <ul className="list-group">
                    <li className="list-group-item">Mais de 250 atributos do cadastro positivo.</li>
                    <li className="list-group-item">Mais de 230 atributos de boletos</li>
                    <li className="list-group-item">Mais de 25 variáveis de hábitos de pagamento em Machine Learning</li>
                    <li className="list-group-item">Dados dos sócios e quadro societário</li>
                    <li className="list-group-item">Atrasos, restritivos, ações e demais apontamentos</li>
                    <li className="list-group-item">Volume e valor das transações do cadastro positivo e de boletos bancários</li>
                    <li className="list-group-item">Porte, CNAE, região e segmentação.</li>
                  </ul>
                  <p><b>*</b> Os dados serão sempre adequados ao perfil, porte, segmento do cliente de forma que todos ou uma seleção deles será utilizada sempre com o objetivo de apresentar os melhores resultados.</p>
                  <br></br>
                  <h4 className="m-0 font-weight-bold text-primary">Aplicações</h4>
                  <p>O Quod Score PJ com boletos pode ser aplicado em diversos momentos do ciclo de crédito do cliente</p>
                  <ul className="list-group">
                    <li className="list-group-item">Na aquisição de clientes, conhecendo e filtrando melhor o público potencial.</li>
                    <li className="list-group-item">Avaliando a concessão e definição de limites de crédito para operações financeiros e vendas mercantis</li>
                    <li className="list-group-item">Validando a capacidade financeira e operacional de toda a cadeia de fornecimento</li>
                    <li className="list-group-item">Auxiliando na orientação foco na cobrança</li>
                    <li className="list-group-item">Também como o maior insumo na construção de políticas de crédito, dentro de motores e decisões automatizadas.</li>
                   
                  </ul>
                  <p>O Quod Score PJ com boletos diferencia-se de todas as outras soluções existentes no mercado por utilizar a melhor capacidade de modelagem analítica do mercado, bem como a utilização da maior quantidade de dados já vista na construção de um score de crédito.
                  Englobando a combinação de uma matriz de 12 diferentes fórmulas estatísticas. Sendo a única solução de mercado com extrema, robustez, eficiência e poder preditivo, independentemente do porte da empresa analisada, conforme ilustrado na figura abaixo</p>
                  <img src={segmento} width={650}/>

                  <p>Além da pontuação, o modelo também retorna os Reason Codes, que são frases que explicam os motivos pelos quais o CNPJ consultado não atingiu a pontuação máxima do SCORE. </p>
              </>
              :
              <>
                <div className="modal-body">
                  <h4 className="m-0 font-weight-bold text-success">Como ler o Quod Score?</h4>
                  <br></br>
                  <p>
                  O Quod score é uma pontuação baseada principalmente nos dados do <b>cadastro positivo</b>, calculada a partir de técnicas estatísticas avançadas, que vai de 300 a 1000. Com ele você tem informações mais precisas sobre a probabilidade de pagamento do consumidor para tomar suas decisões com segurança.
                  </p>
                  <p>
                    
                    Quanto mais alto o score,<b> maior a chance de o consumidor pagar</b>  seus compromissos financeiros nos próximos meses.
                  </p>
                  <p>
          Para facilitar sua análise, nós dividimos a pontuação de acordo com a probabilidade média de um grupo de consumidores honrar seus pagamentos em risco alto, médio e baixo.
          
                  </p>
                  <h4 className="m-0 font-weight-bold text-primary">Entenda o Cadastro Positivo</h4>
                  <br></br>
                  <p>
                  O banco de dados do cadastro positivo <b>vai além de atrasos em pagamentos.</b>  Analisamos mais de 100 atributos compartilhados por diversas fontes, considerando também os hábitos de pagamento do cliente e seu histórico de crédito.
                  </p>
                  <br></br>
                  <h4 className="m-0 font-weight-bold text-info">Tipos de informações analisadas:</h4>
                  <br></br>
                  <ul className="list-group">
                    <li className="list-group-item">Financiamentos</li>
                    <li className="list-group-item">Empréstimos</li>
                    <li className="list-group-item">Consórcio</li>
                    <li className="list-group-item">Cheque Especial</li>
                    <li className="list-group-item">Cartão de Crédito</li>
                    <li className="list-group-item">Período de Contrato</li>
                    <li className="list-group-item">Quantidade de contratos</li>
                    <li className="list-group-item">Tipo de Produtos Contratados</li>
                    <li className="list-group-item">Período Negativado</li>
                    <li className="list-group-item">Quantidade de Negativações</li>
                    <li className="list-group-item">Atrasos Vigentes</li>
                    <li className="list-group-item">histórico de Atrasos</li>
                  </ul>
                  <br></br>
                  <h4 className="m-0 font-weight-bold text-secondary">Origem dos dados positivos:</h4>
                  <br></br>
                  <ul className="list-group">
                    <li className="list-group-item">Bancos</li>
                    <li className="list-group-item">Instituições Financeiras</li>
                    <li className="list-group-item">Empresas de Telefonia</li>
                    <li className="list-group-item">Empresas de Energia Elétrica</li>
                    <li className="list-group-item">Empresas de Água</li>
                    <li className="list-group-item">Empresas de Gás</li>
                    <li className="list-group-item">Empresas de Varejo</li>
                    <li className="list-group-item">Empresas de Atacado</li>
                    <li className="list-group-item">Fintechs</li>
                    <li className="list-group-item">Empresas de Cartões</li>
                    <li className="list-group-item">Empresas de Empréstimos</li>
                    </ul>
                  
                </div>
              </>
              
            }

              </Dialog>
            </div>
          </div>
      </div>
    </Panel>
    <br></br>
    {score.perfilMercado && (
      <Panel header={t('indicadores')} style={{width:'98%'}}>
      <div className="grid">
        <div className="col p-fluid flex flex-wrap">
          <Card className="flex-score flex-column" header={headerMercado} style={{color: 'rgb(100, 181, 246)', borderLeft: '4px solid', background:'rgb(248,248,255)', height:'433px'}}>
            <div className="grid mt-3">
              <div className="col-6 flex-score flex-column p-3 text-center border-right-1 surface-border">
                <span className="text-color text-2xl font-semibold">{score.perfilMercado}</span>
                <span className="text-color font-semibold">{score.mensagemPerfilMercado}</span>
              </div>
              <div className="col-6 flex-score flex-column p-3 text-center ">
                <span className="text-color text-2xl font-semibold">Conceito</span>
                <span className="text-color font-semibold">Apresenta uma visão de como o consumidor se relaciona com o mercado, com base em produtos financeiros que utiliza (cartão de crédito, cheque especial, empréstimos entre outros), quantidade e a frequência de contratações, quanto maior a nota mais saudavel o relacionamento com o mercado.</span>
              </div>
            </div>
          </Card>
        </div>
        <div className="col p-fluid flex flex-wrap">
        <Card className="flex-score flex-column" header={headerPontualidade} style={{color: 'rgb(77, 182, 172)', borderLeft: '4px solid', background:'rgb(248,248,255)', height:'433px'}}>
            <div className="grid mt-3">
              <div className="col-6 flex-score flex-column p-3 text-center border-right-1 surface-border">
                <span className="text-color text-2xl font-semibold">{score.pontualidadePagamento}</span>
                <span className="text-color font-semibold">{score.mensagemPontualidadePagamento}</span>
              </div>
              <div className="col-6 flex-score flex-column p-3 text-center ">
                <span className="text-color text-2xl font-semibold">Conceito</span>
                <span className="text-color font-semibold">Indica a pontualidade de pagamento do consumidor, como arca com seus compromissos financeiros, se possui atrasos vigentes, se efetua seus pagamentos em dia.</span>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Panel>
    
    )}
    {score.motivos  && (
    <Panel header={t('motivos')} style={{width:'98%'}}>
       { score.motivos.map((motivo:string) => {
        return (
          <>
            <Card className="flex-score flex-column" header={headerCard()} style={{color: '#6219B6', borderLeft: '4px solid', background:'rgb(248,248,255)'}}>
              <div className="grid">
                <div className="col-6 flex-score flex-column text-center  surface-border">
                  
                  <span className="text-color font-semibold">{motivo}</span>
                </div>
               
              </div>
            </Card>
            <br></br>
          </>
        )
       }) }
       
    </Panel>
    )}
    </>
  )
}
