
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Card } from "primereact/card";

import { MeterGroup } from 'primereact/metergroup';



export default function IndicadorNegocio({
  indicadores
}: {
  indicadores: any
}) {
  const { t } = useTranslation();
  const headerCard= (descricao:string) => {
    return <div className="flex-score align-items-center" style={{padding: '1.5rem'}}><div className="flex-score justify-content-center align-items-center p-2 border-round" style={{backgroundColor: '#60a5fa'}}><i className="pi pi-chart-line" style={{color: "#FFF"}}></i></div><span className="text-xl ml-2 font-semibold">{descricao}</span></div>
  } 
  const start = ({ totalPercent }: {totalPercent: number}) => (
    <div className="flex justify-content-between mt-3 mb-2 relative">
       
        <span
            style={{ width: totalPercent + '%' }}
            className="absolute text-right"
        >
            {totalPercent}
        </span>
      
    </div>
);


const meter = (props: any) => (
  <span
      
      key={props.index}
      style={{
          background: `linear-gradient(to right, ${props.color1}, ${props.color2})`,
          width: props.percentage + '%',
      }}
  />
);
const meterFirst = (props: any) => (
  <span
      
      key={props.index}
      style={{
          background: `linear-gradient(to right, ${props.color1}, ${props.color2})`,
          width: props.percentage + '%',
          borderTopLeftRadius: '6px',
          borderBottomLeftRadius: '6px',
      }}
  />
);
const meterLast = (props: any) => (
  <span
      
      key={props.index}
      style={{
          background: `linear-gradient(to right, ${props.color1}, ${props.color2})`,
          width: props.percentage + '%',
          borderTopRightRadius: '6px',
          borderBottomRightRadius: '6px',
      }}
  />
);

const getValues = (score:number) => {
  const values = [
    {
        label: 'Apps',
        color1: '#34d399',
        color2: '#fbbf24',
        value: score >= 25 ? 25 : score,
        icon: 'pi pi-table',
        meterTemplate:meterFirst
    },
    {
        label: 'Messages',
        color1: '#fbbf24',
        color2: '#60a5fa',
        value: score >= 50 ? 25 : score - 25,
        icon: 'pi pi-inbox',
    }, 
    {
        label: '',
        color1: '#60a5fa',
        color2: '#c084fc',
        value: score >= 75 ? 25 : score - 50,
        icon: '',
    },
    {
        label: 'System',
        color1: '#c084fc',
        color2: '#c084fc',
        value:  score - 75,
        icon: 'pi pi-cog',
        meterTemplate: meterLast,
    }
  ]
    return values.filter((s) => s.value > 0);
}
const values = [
  {
      label: 'Apps',
      color1: '#34d399',
      color2: '#fbbf24',
      value: 25,
      icon: 'pi pi-table',
      meterTemplate:meterFirst
  },
  {
      label: 'Messages',
      color1: '#fbbf24',
      color2: '#60a5fa',
      value: 15,
      icon: 'pi pi-inbox',
  }, 
  {
      label: '',
      color1: '#60a5fa',
      color2: '#c084fc',
      value: 20,
      icon: '',
  },
  {
      label: 'System',
      color1: '#c084fc',
      color2: '#c084fc',
      value: 10,
      icon: 'pi pi-cog',
      meterTemplate: meter,
  },
];
const labelList = ({ values }: {values: any[]}) => (
  <div className="flex flex-wrap gap-3">
      
  </div>
);
  return (<>
     {indicadores.map((indicador:any) => {  return ( <>
    <Panel header={t('indicadores') + ' - ' + indicador.grupo} className="panel-renda" style={{width:'98%'}}>
       { indicador.itens.map((item:any) => {
        return (
          <>
          
            <Card className="flex-score flex-column" header={headerCard(item.nome)} style={{color: '#60a5fa', borderLeft: '4px solid'}}>
              <div className="grid mt-3">
                <div className="col-6 flex-score flex-column p-3 text-center border-right-1 surface-border">
                  {item.mensagem ? <span className="text-color font-semibold">{item.mensagem}</span> :  <MeterGroup labelPosition="start" values={getValues(item.score)} start={start} meter={meter} labelList={labelList} />}
                </div>
                <div className="col-6 flex-score flex-column p-3 text-center ">
                  <span className="text-color text-2xl font-semibold">Conceito</span>
                  <span className="text-color font-semibold">{item.conceito}</span>
                </div>
              </div>
            </Card>
            <br></br>
          </>
        )
       }) }
       
    </Panel>
    <br></br>
    </>
    )
    }) }
  </>)
}
