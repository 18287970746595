
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";




export default function DadosConsulta({
  dadosConsulta
}: {
    dadosConsulta: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('dadosConsulta')} style={{width:'98%'}}>
       
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('nome')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.nome} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('nomeMae')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.nomeMae ? dadosConsulta.nomeMae : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('documento')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.documento} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('situacao')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.situacao ? dadosConsulta.situacao : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('dataNascimento')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.dataNascimento ? dadosConsulta.dataNascimento :  'Não Informado'} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('sexo')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.sexo ? dadosConsulta.sexo : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('fone')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.telefone ? dadosConsulta.telefone : 'Não Informado'} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('celular')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.celular ? dadosConsulta.celular : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('data')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.data ? dadosConsulta.data : 'Não Informado'} />
            </div>
          </div>
          <div className="col">
          <div >
                <label className="label-dados">{t('email')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.email ? dadosConsulta.email : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
                <label className="label-dados">{t('endereco')}</label>
                 <Chip style={{width:"100%"}} label={dadosConsulta.endereco ?  dadosConsulta.endereco: 'Não Informado'} />
            </div>
          </div>
          
      </div>
    </Panel>
  )
}
