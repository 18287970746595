import axios, { InternalAxiosRequestConfig, ParamsSerializerOptions } from 'axios'
import qs from 'qs'
import { getState } from '../components/globalState/GlobalStateProvider'


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  paramsSerializer: {
    serialize: (params: Record<string, any>, options?: ParamsSerializerOptions) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
})

const goToLogin = () => {
  window.location.href = '/creditbureau/login'
}

const goToChangePassword = () => {
  window.location.href = '/creditbureau/alterarsenha'
}

// Add a request interceptor
axiosInstance.interceptors.request.use(
  
  function (config: InternalAxiosRequestConfig<any>) {
    const token = getState().token
    
    if (!token && !config.url?.includes('authentication') && !config.url?.includes('changepassword') && !config.url?.includes('recuperation')) goToLogin()
    
    config.headers.Authorization = 'Bearer ' +  token;
   
    return { ...config, headers: config.headers }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    if(response.status === 206)goToChangePassword()
    return response
  },
  function (error) {
    
    if (!error.config.url?.includes('authentication')  && (error?.response?.status === 403 || error?.response?.status === 401)) goToLogin()
    return Promise.reject(error)
  }
)

export const http = Object.freeze(axiosInstance)
