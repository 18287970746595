import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import ClienteList from './ClienteList';
import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import ClienteCadastrar from './ClienteCadastrar';
import { Messages } from 'primereact/messages';
import postClient from "../../service/postClient";
import putClient from '../../service/putClient';

export default function ClientePage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const [client, setClient] = useState<any | null>(null);
  const labelButton = t('cadastrar')
  const saveClient = (client:any) => {
    if(client.id){
      putClient(client).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setClient(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    else{
      postClient(client).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setClient(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    
    
  }
  const startContent = (
    <React.Fragment>
        <Button label={labelButton} icon="pi pi-plus" style={{backgroundColor:'#183462'}} onClick={() => {setClient({})}}/>
    </React.Fragment>
);

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-tag" style={{ fontSize: '2.0rem' }}></i> {t('clientes')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!client && (
          <>
            <Toolbar start={startContent} />
            <br></br>
            <br></br>
            <ClienteList onEdit={(client:any) => { setClient(client);}}></ClienteList>
          </>
        )}
        {client && (
          <>
           <ClienteCadastrar msgs={msgs} client={client} onSave={saveClient} onClose={() => {setClient(null)}}></ClienteCadastrar>
          </>
        )}
      </div>
                
    </Container>
  )
}
