import { InputText } from "primereact/inputtext";
import { Controller, useForm } from 'react-hook-form';
import { Button } from "primereact/button";
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { InputMask } from "primereact/inputmask";
import { Image } from 'primereact/image';

interface FormData {
    id?: string;
    whatsappSuporte: string;
    logoEmpresa: FileList | null;
    logoRevendaQuod: FileList | null;
    loginQuod: string;
    senhaQuod: string;
    loginSPC: string;
    senhaSPC: string;
    loginBVS: string;
    senhaBVS: string;
    loginSerasa: string;
    senhaSerasa: string;
    loginInfoSimples: string;
    senhaInfoSimples: string;
    loginProtesto: string;
    senhaProtesto: string;
    tokenScorePositivo: string;
    observacao: string;
}

interface ParametrosData {
    id?: string;
    whatsappSuporte: string;
    logoEmpresa?: string;
    logoRevendaQuod?: string;
    loginQuod: string;
    senhaQuod: string;
    loginSPC: string;
    senhaSPC: string;
    loginBVS: string;
    senhaBVS: string;
    loginSerasa: string;
    senhaSerasa: string;
    loginInfoSimples: string;
    senhaInfoSimples: string;
    loginProtesto: string;
    senhaProtesto: string;
    tokenScorePositivo: string;
    observacao: string;
}

interface ParametrosAplicacaoCadastrarProps {
    saveParametros: (parametros: ParametrosData) => void;
    parametros?: FormData;
    isEditing?: boolean;
}

export default function ParametrosAplicacaoCadastrar({
    saveParametros,
    parametros,
    isEditing = false,
}: ParametrosAplicacaoCadastrarProps) {
    const { control, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
        defaultValues: parametros || {}
    });
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>({});
    const [logoEmpresa, setLogoEmpresa] = useState<string | undefined | FileList>(undefined);
    const [logoRevendaQuod, setLogoRevendaQuod] = useState<string | undefined | FileList>(undefined);

    useEffect(() => {
        if (parametros) {
            if (parametros.logoEmpresa && typeof parametros.logoEmpresa === 'string') {
                const base64Prefix = "data:image/jpeg;base64,";
                const logoEmpresaBase64 = `${base64Prefix}${parametros.logoEmpresa}`;
                setLogoEmpresa(logoEmpresaBase64);
            }
            if (parametros.logoRevendaQuod && typeof parametros.logoRevendaQuod === 'string') {
                const base64Prefix = "data:image/jpeg;base64,";
                const logoRevendaQuodBase64 = `${base64Prefix}${parametros.logoRevendaQuod}`;
                setLogoRevendaQuod(logoRevendaQuodBase64);
            }
            reset(parametros);
        }
    }, [parametros, reset]);

    const togglePasswordVisibility = (fieldName: string) => {
        setShowPassword(prev => ({
            ...prev,
            [fieldName]: !prev[fieldName],
        }));
    };

    const onSubmit = async (data: FormData) => {
        try {
            const parametrosData: ParametrosData = await processFormData(data);
            saveParametros(parametrosData);
        } catch (error) {
            console.error('Erro ao processar os parâmetros:', error);
        }
    };

    const processFormData = async (data: FormData): Promise<ParametrosData> => {
        let logoEmpresaBase64: string | undefined = undefined;
        if (data.logoEmpresa && data.logoEmpresa.length > 0) {
            const logoEmpresaFile = data.logoEmpresa[0];
            if (logoEmpresaFile instanceof File) {
                logoEmpresaBase64 = await fileToBase64(logoEmpresaFile);
            }
        }
        let logoRevendaQuodBase64: string | undefined = undefined;
        if (data.logoRevendaQuod && data.logoRevendaQuod.length > 0) {
            const logoRevendaQuodFile = data.logoRevendaQuod[0];
            if (logoRevendaQuodFile instanceof File) {
                logoRevendaQuodBase64 = await fileToBase64(logoRevendaQuodFile);
            }
        }
        if (data.whatsappSuporte && data.whatsappSuporte.length > 0) {
            data.whatsappSuporte = data.whatsappSuporte.replace(/[\s-]/g, '');
        }
        return {
            id: data.id,
            whatsappSuporte: data.whatsappSuporte,
            logoEmpresa: logoEmpresaBase64,
            logoRevendaQuod: logoRevendaQuodBase64,
            loginQuod: data.loginQuod,
            senhaQuod: data.senhaQuod,
            loginSPC: data.loginSPC,
            senhaSPC: data.senhaSPC,
            loginBVS: data.loginBVS,
            senhaBVS: data.senhaBVS,
            loginSerasa: data.loginSerasa,
            senhaSerasa: data.senhaSerasa,
            loginInfoSimples: data.loginInfoSimples,
            senhaInfoSimples: data.senhaInfoSimples,
            loginProtesto: data.loginProtesto,
            senhaProtesto: data.senhaProtesto,
            tokenScorePositivo: data.tokenScorePositivo,
            observacao: data.observacao,
        };
    };

    const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };

    const getFormErrorMessage = (name: keyof FormData) => {
        return errors[name] ? <small className="p-error">{errors[name]?.message}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid grid formgrid">
            <div className="grid">
                {[
                    { name: 'loginQuod', label: t('loginQuod'), isPassword: false },
                    { name: 'senhaQuod', label: t('senhaQuod'), isPassword: true },
                    { name: 'loginSPC', label: t('loginSPC'), isPassword: false },
                    { name: 'senhaSPC', label: t('senhaSPC'), isPassword: true },
                    { name: 'loginBVS', label: t('loginBVS'), isPassword: false },
                    { name: 'senhaBVS', label: t('senhaBVS'), isPassword: true },
                    { name: 'loginSerasa', label: t('loginSerasa'), isPassword: false },
                    { name: 'senhaSerasa', label: t('senhaSerasa'), isPassword: true },
                    { name: 'loginInfoSimples', label: t('loginInfoSimples'), isPassword: false },
                    { name: 'senhaInfoSimples', label: t('senhaInfoSimples'), isPassword: true },
                    { name: 'loginProtesto', label: t('loginProtesto'), isPassword: false },
                    { name: 'senhaProtesto', label: t('senhaProtesto'), isPassword: true },
                    { name: 'tokenScorePositivo', label: t('tokenScorePositivo'), isPassword: false }
                ].map((field, index) => (
                    <div className="col-12 md:col-6" key={index}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Controller
                                name={field.name as keyof FormData}
                                control={control}
                                render={({ field }) => (
                                    <InputText
                                        id={field.name}
                                        {...field}
                                        value={field.value as string}
                                        type={field.name.includes('senha') && !showPassword[field.name] ? 'password' : 'text'}
                                    />
                                )}
                            />
                            {field.isPassword && (
                                <button
                                    type="button"
                                    style={{ position: 'absolute', right: '10px', background: 'none', border: 'none', cursor: 'pointer' }}
                                    onClick={() => togglePasswordVisibility(field.name)}
                                >
                                    <i className={`pi ${showPassword[field.name] ? 'pi-eye' : 'pi-eye-slash'}`} />
                                </button>
                            )}
                        </div>
                        {getFormErrorMessage(field.name as keyof FormData)}
                    </div>
                ))}
                <div className="col-12 md:col-6">
                    <label htmlFor="whatsappSuporte">
                        {t('whatsappDoSuporte')}
                    </label>
                    <Controller
                        name="whatsappSuporte"
                        control={control}
                        rules={{
                            required: 'Whatsapp é obrigatório',
                            pattern: {
                                value: /^\d{2}\s\d{2}\s\d{5}-(\d{4}|\d{3})$/,
                                message: 'Formato inválido',
                            },
                        }}
                        render={({ field }) => (
                            <InputMask
                                mask="99 99 9?9999-9999"
                                value={field.value}
                                onChange={field.onChange}
                                placeholder="55 99 99999-9999"
                                className="p-inputtext p-component"
                            />
                        )}
                    />
                    {getFormErrorMessage('whatsappSuporte')}
                </div>
                <div className="col-12">
                    <label htmlFor="observacao">
                        {t('observacao')}
                    </label>
                    <Controller
                        name="observacao"
                        control={control}
                        render={({ field }) => (
                            <InputTextarea id={field.name} {...field} rows={3} />
                        )}
                    />
                </div>
                <div className="col-12 md:col-6">
                    <label htmlFor="logoEmpresa">
                        {t('logoEmpresa')}
                    </label>
                    <Controller
                        name="logoEmpresa"
                        control={control}
                        render={({ field }) => (
                            <FileUpload
                                accept="image/jpeg"
                                maxFileSize={1000000}
                                chooseLabel="Escolher"
                                customUpload={false}
                                mode="advanced"
                                auto
                                onSelect={(e) => field.onChange(e.files)}
                                onRemove={() => field.onChange(null)}
                            />
                        )}
                    />
                    <div style={{ display: "flex", justifyContent: "center", paddingTop: "1rem" }}>
                    <Image src={logoEmpresa as string} alt="logoEmpresa" width="250rem" />
                    </div>
                </div>
                <div className="col-12 md:col-6">
                    <label htmlFor="logoRevendaQuod">
                        {t('logoRevendaQuod')}
                    </label>
                    <Controller
                        name="logoRevendaQuod"
                        control={control}
                        render={({ field }) => (
                            <FileUpload
                                accept="image/jpeg"
                                maxFileSize={1000000}
                                chooseLabel="Escolher"
                                customUpload={false}
                                mode="advanced"
                                auto
                                onSelect={(e) => field.onChange(e.files)}
                                onRemove={() => field.onChange(null)}
                            />
                        )}
                    />
                    <div style={{ display: "flex", justifyContent: "center", paddingTop: "1rem" }}>
                    <Image src={logoRevendaQuod as string} alt="logoRevendaQuod" width="250rem" />
                    </div>
                </div>
            </div>
            <div className="col-12" style={{ display: "flex", justifyContent: "flex-end", paddingTop: "1rem" }}>
                <Button style={{ backgroundColor: "#183462", width: "8rem", marginRight: "1rem" }} label={isEditing ? "Atualizar" : "Salvar"} type="submit" />
            </div>
        </form>
    );
}
