
import { useTranslation } from "react-i18next";
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import NadaConsta from "../../../components/nadaConsta";




export default function Pendencias({
  pendencias
}: {
  pendencias: any
}) {
  const { t } = useTranslation();
  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> <span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('pendencias')}</span></span></div>;
  }
  return (
    <Panel headerTemplate={headerTemplate} style={{width:'98%'}}>
       {!pendencias ? ( <NadaConsta />) : (<>
       <DataTable size="small" value={pendencias} tableStyle={{ minWidth: '50rem' }}>
          <Column field="dataRegistro" header={t('dataRegistro')}></Column>
          <Column field="dataVencimento" header={t('dataVencimento')}></Column>
          <Column field="motivo" header={t('natureza')}></Column>
          <Column field="contrato" header={t('contrato')}></Column>
          <Column field="associado" header={t('credor')}></Column>
          <Column field="cidade" header={t('cidadeUF')}></Column>
          <Column field="valor" header={t('valor')}></Column>
      </DataTable>
      </>)}
    </Panel>
  )
}
