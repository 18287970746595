
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";





export default function MaisNegocio({
  maisNegocio
}: {
  maisNegocio: any
}) {
  const { t } = useTranslation();

 

const headerCard= () => {
  return <div className="flex-score align-items-center" style={{padding: '1.5rem'}}><div className="flex-score justify-content-center align-items-center p-2 border-round" style={{backgroundColor: '#6219B6'}}><i className="pi pi-info" style={{color: "#FFF"}}></i></div><span className="text-xl ml-2 font-semibold">Motivo</span></div>
} 
   

	
  
  return (
    <>
    <Panel header={t('maisNegocio')} style={{width:'98%'}}>
       <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
              <label className="label-dados">{t('operacao')}</label>
              <Chip style={{width:"100%"}} label={maisNegocio.operacao ? maisNegocio.operacao : 'Não Informado'} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('risco')}</label>
              <Chip style={{width:"100%"}} label={maisNegocio.risco ? maisNegocio.risco : 'Não Informado'} />
            </div>
          </div>
      </div>
      <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
              <label className="label-dados">{t('capacidadePagamento')}</label>
              <Chip style={{width:"100%"}} label={maisNegocio.capacidadePagamento ? maisNegocio.capacidadePagamento : 'Não Informado'} />
            </div>
          </div>
          <div className="col">
            <div >
              <label className="label-dados">{t('recomendacao')}</label>
              <Chip style={{width:"100%"}} label={maisNegocio.recomendacao ? maisNegocio.recomendacao : 'Não Informado'} />
            </div>
          </div>
      </div>
      <br></br>
      <br></br>
      {maisNegocio.motivos  && (
       <>
          {maisNegocio.motivos.map((motivo:string) => {
            return (
              <>
                <Card className="flex-score flex-column" header={headerCard()} style={{color: '#6219B6', borderLeft: '4px solid', background:'rgb(248,248,255)'}}>
                  <div className="grid">
                    <div className="col-6 flex-score flex-column text-center  surface-border">
                      
                      <span className="text-color font-semibold">{motivo}</span>
                    </div>
                  
                  </div>
                </Card>
                <br></br>
              </>
            )
          }) }
        </>

      )}
    </Panel>
   
    
    
    </>
  )
}
