import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import findRelatorio from '../../service/findRelatorio';
import { SortOrder } from "primereact/api";
import getRelatorioDownload from "../../service/getRelatorioDownload";
import { useGlobalState } from "../globalState/GlobalStateProvider";
import getRelatorioNotificacao from "../../service/getRelatorioNotificacao";
import {GlobalSaveData} from "../globalState/GlobalSaveData";

const TopbarRelatorio = () => {
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [relatorios, setRelatorios] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const {state} = useGlobalState();
  
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: 'data',
    sortOrder: SortOrder.DESC
  });

  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const loadLazyData = () => {
    findRelatorio(lazyState).then(data => {setTotalRecords(data.total);setRelatorios(data.relatorios)}).catch(() => {setTotalRecords(0);setRelatorios(undefined)});
  }
  const dialogFooterTemplate = () => {
    return <Button label="Ok" icon="pi pi-check" onClick={() => setDialogVisible(false)} />;
  };

  const downloadResponse = (relatorio:any) => {
    getRelatorioDownload(relatorio.id).then( (blobFile:Blob) => {
      loadLazyData();
      getRelatorioNotificacao().then((data) => {
        GlobalSaveData("relatorios", data);
      });
      const url = window.URL.createObjectURL(
        new Blob([blobFile], {type: "octet/stream"}),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        relatorio.nome + relatorio.tipoArquivo,
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      document.body.removeChild(link);
    })
  };
  
  const downloadTemplate = (consulta:any) => {
    return consulta.tamanho != 0 ? <Button icon="pi pi-download" onClick={() => {downloadResponse(consulta)}} style={{background:'transparent', color:'gray', border: 'none'}} /> : '';
  };

  useEffect(() => {
    loadLazyData();
  },[lazyState])

  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <div>
      <Button className="button_relatorio" onClick={() => {loadLazyData(); setDialogVisible(true)}} icon="pi pi-file-export" badge={state.relatorios} badgeClassName="p-badge-danger badge_relatorio" rounded text style={{color:'white'}} ></Button>
      <Dialog header="Relatórios" visible={dialogVisible} style={{ width: '75vw' }} 
        modal contentStyle={{ height: '350px' }} onHide={() => setDialogVisible(false)} >
          <DataTable value={relatorios} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
          emptyMessage="Sem registros" size="small"
          onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
          rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage} scrollable scrollHeight="flex" tableStyle={{ minWidth: '50rem' }}>
              <Column field="data" header={t('data')}></Column>
              <Column field="nome" header={t('arquivo')}></Column>
              <Column field="tipo" header={t('tipo')}></Column>
              <Column field="tamanho" header={t('tamanho')}></Column>
              <Column field="download" header={t('downloads')}></Column>
              <Column field="tipoArquivo" header={t('tipoArquivo')}></Column>
              <Column body={downloadTemplate} />
          </DataTable>
      </Dialog>
    </div>
  );
};

export default TopbarRelatorio;
