import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import { InputSwitch } from 'primereact/inputswitch';
import { SetStateAction, useEffect, useState } from "react";
import { Tree, TreeCheckboxSelectionKeys, TreeMultipleSelectionKeys } from "primereact/tree";
import getPermissao from "../../service/getPermissao";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";


export default function ClienteCadastrar({client, onClose, onSave, msgs}:{client:any, onClose: () => void, onSave: (user:any) => void, msgs:any}) {
  const { t } = useTranslation();
  const [inativo, setInativo] = useState(client.inativo ? client.inativo : false);
  const defaultValues = {
    nome: '',
    email: '',
    documento:'',
    responsavel: '',
    telefone:'',
    whatsapp:'',
    observacoes: '',
    codigoClienteErp: '',
    nomeFantasia: '',
    cep: '',
    logradouro: '',
    numeroEndereco: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    dataCadastro: client.dataCadastro ? new Date(client.dataCadastro) : null,
    dataContratacao: client.dataContratacao ? new Date(client.dataContratacao) : null,
    diaVencimentoFatura: '',
    vcm: 0.00,
    cnae: ''
  };
  defaultValues.nome = client.nome ? client.nome.toString() : ''
  defaultValues.email = client.email ? client.email.toString() : ''
  defaultValues.documento = client.documento ? client.documento.toString() : ''
  defaultValues.responsavel = client.responsavel ? client.responsavel.toString() : ''
  defaultValues.telefone = client.telefone ? client.telefone.toString() : ''
  defaultValues.whatsapp = client.whatsapp ? client.whatsapp.toString() : ''
  defaultValues.observacoes = client.observacoes ? client.observacoes.toString() : ''
  defaultValues.codigoClienteErp = client.codigoClienteErp ? client.codigoClienteErp.toString() : ''
  defaultValues.nomeFantasia = client.nomeFantasia ? client.nomeFantasia.toString() : ''
  defaultValues.cep = client.cep ? client.cep.toString() : ''
  defaultValues.logradouro = client.logradouro ? client.logradouro.toString() : ''
  defaultValues.numeroEndereco = client.numeroEndereco ? client.numeroEndereco.toString() : ''
  defaultValues.complemento = client.complemento ? client.complemento.toString() : ''
  defaultValues.bairro = client.bairro ? client.bairro.toString() : ''
  defaultValues.cidade = client.cidade ? client.cidade.toString() : ''
  defaultValues.uf = client.uf ? client.uf.toString() : ''
  defaultValues.dataCadastro = client.dataCadastro ? new Date(client.dataCadastro) : null
  defaultValues.dataContratacao = client.dataContratacao ? new Date(client.dataContratacao) : null
  defaultValues.diaVencimentoFatura = client.diaVencimentoFatura ? client.diaVencimentoFatura.toString() : ''
  defaultValues.vcm = client.vcm ? client.vcm : 0.00
  defaultValues.cnae = client.cnae ? client.cnae.toString() : ''

  const cnpj = (v:string) =>{
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
}

const cpf = (v:string) => {
    v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                             //de novo (para o segundo bloco de números)
    v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}

const maskCpfCnpj = (input:string) =>{
  if(input.length > 14){
    return cnpj(input)
  }
  else{
    return cpf(input)
  }
}

const validDocument = (document:string) => {
    let valid = true
    if(document.length == 11){
      valid = verifyCPF(document)
    }
    else{
      valid = verifyCnpj(document)
    }
    if(valid){
      msgs.current?.clear();
    }
    else{
      msgs.current?.clear();
      msgs.current?.show([
         { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Documento inválido', closable: false }
             
      ]);
    }
  }

  const verifyCnpj = (cnpj:string) => {
 
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj == '') return false;
     
    if (cnpj.length != 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho) + '';
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(0)))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(1)))
          return false;
           
    return true;
    

}

  const  verifyCPF = (strCPF:string) => {
    strCPF = strCPF.replaceAll('.','').replace('-','')
    let Soma;
    let Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;
    if (strCPF == "11111111111") return false;
    if (strCPF == "22222222222") return false;
    if (strCPF == "33333333333") return false;
    if (strCPF == "44444444444") return false;
    if (strCPF == "55555555555") return false;
    if (strCPF == "66666666666") return false;
    if (strCPF == "77777777777") return false;
    if (strCPF == "88888888888") return false;
    if (strCPF == "99999999999") return false;

    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}
  
  const {
      control,
      formState: { errors},
      handleSubmit
  } = useForm({ defaultValues });

  const onSubmit = (data:any) => {
      data.nome && save(data);
  };

 
  const save = (data:any) => {
    const cliente = {
      id: client.id? client.id : undefined,
      nome:data.nome,
      documento:data.documento.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
      email:data.email,
      responsavel:data.responsavel,
      telefone:data.telefone,
      whatsapp:data.whatsapp,
      observacoes: data.observacoes,
      inativo:inativo,
      codigoClienteErp:data.codigoClienteErp,
      nomeFantasia:data.nomeFantasia,
      cep:data.cep,
      logradouro:data.logradouro,
      numeroEndereco:data.numeroEndereco,
      complemento:data.complemento,
      bairro:data.bairro,
      cidade:data.cidade,
      uf:data.uf,
      dataCadastro:data.dataCadastro,
      dataContratacao:data.dataContratacao,
      diaVencimentoFatura:data.diaVencimentoFatura,
      vcm:data.vcm === 0 ? null : data.vcm,
      cnae:data.cnae
    }
    window.scrollTo(0, 0);
    onSave(cliente);
  }
  
  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  const [addressData, setAddressData] = useState({
    logradouro: client.logradouro || '',
    bairro: client.bairro || '',
    cidade: client.cidade || '',
    uf: client.uf || ''
  });
  
  const fetchAddress = async (cep: string) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (!data.erro) {
        setAddressData(prevData => ({
          ...prevData,
          logradouro: data.logradouro,
          bairro: data.bairro,
          cidade: data.localidade,
          uf: data.uf
        }));
      } else {
        msgs.current?.show({
          sticky: false,
          life: 10000,
          severity: 'error',
          summary: 'Erro',
          detail: 'CEP não encontrado',
          closable: false
        });
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
        <div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                
                  <Controller
                      name="nome"
                      control={control}
                      rules={{ required: 'Nome é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.nome })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="nome" className="font-bold block mb-2 required">{t('razaoSocial')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
                <Controller
                      name="documento"
                      control={control}
                      rules={{ required: 'Documento é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.documento })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="documento" className="font-bold block mb-2 required">{t('cnpj')} </label>
                                  <InputText maxLength={18} min={18} max={18} onKeyUp={() => { field.onChange(cnpj(field.value))}} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onBlur={(e) => validDocument(e.target.value)} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Controller
                      name="telefone"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.telefone })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="telefone" className="font-bold block mb-2">{t('fone')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
            </div>
            <div className="col">
                <Controller
                      name="whatsapp"
                      control={control}
                      
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.whatsapp })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="whatsapp" className="font-bold block mb-2">{t('whatsapp')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Controller
                      name="email"
                      control={control}
                      rules={{ required: 'Email é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.documento })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="email" className="font-bold block mb-2 required">{t('email')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
            </div>
            <div className="col">
                <Controller
                      name="responsavel"
                      control={control}
                      
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.responsavel })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="responsavel" className="font-bold block mb-2">{t('responsavel')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
          <div className="grid">
            
            <div className="col">
                <Controller
                      name="observacoes"
                      control={control}
                      
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.whatsapp })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="observacoes" className="font-bold block mb-2">{t('observacoes')} </label>
                                  <InputTextarea style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
          </div>
        <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <Controller
                      name="codigoClienteErp"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.codigoClienteErp })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="codigoCliente" className="font-bold block mb-2">{t('codigoCliente')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
            </div>
            <div className="col">
                <Controller
                      name="nomeFantasia"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.nomeFantasia })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="nomeFantasia" className="font-bold block mb-2">{t('nomeFantasia')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div >
                  <label className="label-dados" style={{paddingLeft:'0'}}>{t('inativo')}</label>
                  <div style={{width:'100%' , marginTop:'0.5rem'}}>
                    <InputSwitch checked={inativo} value={inativo} onChange={() => setInativo(!inativo)} />
                  </div>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col">
              <Controller
                name="cep"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className={classNames({ 'p-error': errors.cep })}></label>
                    <span className="">
                      <div>
                        <label htmlFor="cep" className="font-bold block mb-2">{t('cep')} </label>
                        <InputText
                          style={{ width: '100%' }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            fetchAddress(e.target.value);
                          }}
                          onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) {e.preventDefault();}}}
                          maxLength={8}
                        />
                      </div>
                    </span>
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
            <div className="col">
              <Controller
                name="logradouro"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className={classNames({ 'p-error': errors.logradouro })}></label>
                    <span className="">
                      <div>
                        <label htmlFor="logradouro" className="font-bold block mb-2">{t('logradouro')} </label>
                        <InputText
                          style={{ width: '100%' }}
                          id={field.name}
                          value={addressData.logradouro}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setAddressData(prevData => ({ ...prevData, logradouro: e.target.value }));
                          }}
                        />
                      </div>
                    </span>
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
            <div className="col">
              <Controller
                name="numeroEndereco"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className={classNames({ 'p-error': errors.numeroEndereco })}></label>
                    <span className="">
                      <div>
                        <label htmlFor="numero" className="font-bold block mb-2">{t('numero')} </label>
                        <InputText
                          style={{ width: '100%' }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                      </div>
                    </span>
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
            <div className="col">
              <Controller
                name="bairro"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className={classNames({ 'p-error': errors.bairro })}></label>
                    <span className="">
                      <div>
                        <label htmlFor="bairro" className="font-bold block mb-2">{t('bairro')} </label>
                        <InputText
                          style={{ width: '100%' }}
                          id={field.name}
                          value={addressData.bairro}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setAddressData(prevData => ({ ...prevData, bairro: e.target.value }));
                          }}
                        />
                      </div>
                    </span>
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
                  <Controller
                        name="complemento"
                        control={control}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.complemento })}></label>
                                <span className="">
                                <div>
                                    <label htmlFor="complemento" className="font-bold block mb-2">{t('complemento')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
            </div>
            <div className="col">
              <Controller
                name="cidade"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className={classNames({ 'p-error': errors.cidade })}></label>
                    <span className="">
                      <div>
                        <label htmlFor="cidade" className="font-bold block mb-2">{t('cidade')} </label>
                        <InputText
                          style={{ width: '100%' }}
                          id={field.name}
                          disabled={true}
                          value={addressData.cidade}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setAddressData(prevData => ({ ...prevData, cidade: e.target.value }));
                          }}
                        />
                      </div>
                    </span>
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
            <div className="col">
              <Controller
                name="uf"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className={classNames({ 'p-error': errors.uf })}></label>
                    <span className="">
                      <div>
                        <label htmlFor="uf" className="font-bold block mb-2">{t('uf')} </label>
                        <InputText
                          style={{ width: '100%' }}
                          disabled={true}
                          id={field.name}
                          value={addressData.uf}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setAddressData(prevData => ({ ...prevData, uf: e.target.value }));
                          }}
                        />
                      </div>
                    </span>
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
                <Controller
                      name="dataCadastro"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataCadastro })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="dataCadastro" className="font-bold block mb-2">{t('dataCadastro')} </label>
                                  <Calendar id='dataCadastro' value={field.value || null}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
            </div>
            <div className="col p-fluid flex flex-wrap">
              <Controller
                      name="dataContratacao"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataContratacao })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="dataContratacao" className="font-bold block mb-2">{t('dataContratacao')} </label>
                                  <Calendar id='dataContratacao' value={field.value || null}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
            </div>
            <div className="col">
                <Controller
                      name="diaVencimentoFatura"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.diaVencimentoFatura })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="diaVencimentoFatura" className="font-bold block mb-2">{t('diaVencimentoFatura')} </label>
                                  <InputText maxLength={2} style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) {e.preventDefault();}}} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
            </div>
            <div className="col">
              <Controller
                name="vcm"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className={classNames({ 'p-error': errors.vcm })}></label>
                    <span className="">
                      <div>
                        <label htmlFor={field.name} className="font-bold block mb-2">{t('vcm')} </label>
                        <InputNumber
                          style={{ width: '100%' }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onValueChange={(e) => field.onChange(e.value)}
                          mode="currency"
                          currency="BRL"
                          locale="pt-BR"
                        />
                      </div>
                    </span>
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
            <div className="col">
                <Controller
                      name="cnae"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.cnae })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="cnae" className="font-bold block mb-2">{t('cnae')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
            <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
        </div> 
    </form>
    
  )
}

