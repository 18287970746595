
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function PontualidadePagamento({
  pontualidadePagamento
}: {
  pontualidadePagamento
  : any
}) {
  const { t } = useTranslation();

  return (
    <Panel header={t('pontualidadePagamento')} style={{width:'98%'}}>
       
       <DataTable size="small" value={pontualidadePagamento.data} tableStyle={{ minWidth: '50rem' }}>
        {pontualidadePagamento.columns.map((col:any) => (
                    <Column key={col.field} field={col.field} header={col.header} />
                ))}
      </DataTable>
    </Panel>
  )
}
