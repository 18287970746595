
import { useTranslation } from "react-i18next";
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function ProtestoQuod({
  protestos
}: {
  protestos: any
}) {
  const { t } = useTranslation();
  
  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> <span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('protestos')}</span></span></div>;
  }

    return (
    <Panel headerTemplate={headerTemplate} style={{width:'98%'}}>     
       <DataTable size="small" value={protestos} tableStyle={{ minWidth: '50rem' }}>
          <Column field="cartorio" header={t('cartorio')}></Column>
          <Column field="endereco" header={t('endereco')}></Column>
          <Column field="cidade" header={t('cidade')}></Column>
          <Column field="uf" header={t('uf')}></Column>
          <Column field="telefone" header={t('telefone')}></Column>
          <Column field="quantidade" header={t('quantidade')}></Column>
          <Column field="valor" header={t('valor')}></Column>
      </DataTable>
    </Panel>
  )
}
