import { classNames } from "primereact/utils";
import { useTranslation } from 'react-i18next';
import { Password } from 'primereact/password';
import Container from '../../components/menu/Container';
import { Controller, useForm } from 'react-hook-form';
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import putUserChangePassword from "../../service/putUserChangePassword";
import { Messages } from "primereact/messages";

export default function Page() {
  const toast = useRef<Toast>(null);
  const msgs = useRef<Messages>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const defaultValues = {
    password: '',
    passwordNew: '',
    passwordConfirm:''
  };
  const {
    control,
    formState: { errors},
    handleSubmit
} = useForm({ defaultValues });
  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };
 
  const submit = (data:any) => {
    if(data.passwordNew != data.passwordConfirm){
      if(toast != null && toast.current != null){
        toast.current.show({severity:'error', summary: 'Error', detail:'Nova senha e confirma nova senha não são iguais', life: 3000});
      }
    }
    else{
      const usuario = {
        senha:data.password,
        novaSenha:data.passwordNew
      }
      putUserChangePassword(usuario).then(data => {
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
             
          ]);
        }
      }).catch(error => {
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: error.message, closable: false }
             
          ]);
        }
      });
    }
  }
  return (
    <>
      <Container>
        <div className="row">
            <div className="col-lg-12 mb-3">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray"><i className="pi pi-key" style={{ fontSize: '2.0rem' }}></i> {t('alterarSenha')}</h1>
              </div>     
            </div>
        </div>
        <div className="card">
          <Toast ref={toast} />
          <Messages ref={msgs} />
          <form onSubmit={handleSubmit(submit)} className="flex flex-column gap-2">
            <div>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">       
                  <Controller
                        name="password"
                        control={control}
                        rules={{ required: 'Senha Atual é Obrigatório' }}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.password })}></label>
                                <span className="">
                                <div>
                                  <label htmlFor="nome" className="font-bold block mb-2 required">{t('senha')} </label>
                                  <Password id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} feedback={false} toggleMask style={{width: '20rem'}}/>
                                  </div>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
                      
                </div>
              </div>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">       
                  <Controller
                        name="passwordNew"
                        control={control}
                        rules={{ required: 'Nova Senha é Obrigatório' }}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.passwordNew })}></label>
                                <span className="">
                                <div>
                                  <label htmlFor="nome" className="font-bold block mb-2 required">{t('novaSenha')} </label>
                                  <Password id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} feedback={false} toggleMask style={{width: '20rem'}}/>
                                  </div>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
                      
                </div>
                <div className="col p-fluid flex flex-wrap">       
                  <Controller
                        name="passwordConfirm"
                        control={control}
                        rules={{ required: 'Repetir Senha é Obrigatório' }}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.passwordConfirm })}></label>
                                <span className="">
                                <div>
                                  <label htmlFor="nome" className="font-bold block mb-2 required">{t('repetirNovaSenha')} </label>
                                  <Password id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} feedback={false} toggleMask style={{width: '20rem'}}/>
                                  </div>
                                </span>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
                      
                </div>
              </div>
            </div> 
            <br></br>
            <br></br>
            <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
                <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
                <Button icon="pi pi-times" onClick={()=>{navigate("/home")}} rounded outlined severity="danger" aria-label="Cancel" />
            </div>    
          </form>
        </div>
      </Container>
    </>
  )
}
