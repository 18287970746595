import { http } from '../../config/axios'
import { User } from '../../interface/User'

export default function getUserCheck(): Promise<string> {
  return http
    .get('api/user/check')
    .then((res) => {
      return res.data as string
    })
    .catch((error) => {
      console.error('Erro getUserCheck', error)
      return 'Erro' as string
      //throw Error(error.response.data)
    })
}
