
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function FaturamentoPresumido({
  faturamentos
}: {
  faturamentos: any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('faturamentoPresumido')} style={{width:'98%'}}>
       
       <DataTable size="small" value={faturamentos} tableStyle={{ minWidth: '50rem' }}>
          <Column field="data" header={t('data')}></Column>
          <Column field="valor" header={t('valor')}></Column>
          <Column field="faixa" header={t('faixaFaturamento')}></Column>
          <Column field="subFaixa" header={t('subFaixaFaturamento')}></Column>
          <Column field="porte" header={t('porte')}></Column>
      </DataTable>
    </Panel>
  )
}
