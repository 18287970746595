import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findClient from '../../service/findClient';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { SortOrder } from 'primereact/api';
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Nullable } from 'primereact/ts-helpers';
import { Toolbar } from 'primereact/toolbar';



export default function ClienteList({onEdit}:{onEdit:(user:any)=>void}) {
  const { t } = useTranslation();
  const [clients, setClients] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const [cliente, setCliente] = useState<Nullable<String | null>>(null);
  const [documento, setDocumento] = useState<Nullable<String | null>>(null);
  const [codigoClienteErp, setCodigoClienteErp] = useState<Nullable<String | null>>(null);

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    cliente: cliente,
    documento: documento,
    codigoClienteErp: codigoClienteErp,
    sortField: 'nome',
    sortOrder: SortOrder.DESC
  });

  const changeDocumento = (doc:Nullable<String>) => {
    if(doc){
      doc = doc ? doc.replaceAll('.','').replaceAll('-','').replaceAll('/','') : null
      setDocumento(doc);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        cliente: cliente,
        documento: doc,
        codigoClienteErp: codigoClienteErp,
        sortField: 'nome',
        sortOrder: SortOrder.DESC
      });
    }
   }

   const changeCliente = (cli:Nullable<String>) => {
      if(cli){
        setCliente(cli);
        setlazyState({
          first: lazyState.first,
          rows: lazyState.rows,
          page: (lazyState.first / lazyState.rows) + 1,
          cliente: cli,
          documento: documento,
          codigoClienteErp: codigoClienteErp,
          sortField: 'nome',
          sortOrder: SortOrder.DESC
        });
      }
   }

   const changeCodigoClienteErp = (codigo: Nullable<String>) => {
    if (codigo) {
      setCodigoClienteErp(codigo);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        cliente: cliente,
        documento: documento,
        codigoClienteErp: codigo,
        sortField: 'nome',
        sortOrder: SortOrder.DESC
      });
    }
  }

  const handleDocumento = (event:any) => {
    event.target.value =  maskCpfCnpj(event.target.value)
  }

  const cnpj = (v:string) =>{
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
}

const cpf = (v:string) => {
    v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                             //de novo (para o segundo bloco de números)
    v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}

const cols = [
  { field: 'documento', header: 'Documento' },
  { field: 'nome', header: 'Nome' },
  { field: 'email', header: 'Email' },
  { field: 'inativo', header: 'Inativo' }
];
const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
const exportPdf = () => {
  import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
          const lazy = {
            first: 0,
            rows: totalRecords,
            page:  1,
            cliente: cliente,
            documento: documento,
            codigoClienteErp: codigoClienteErp,
            sortField: 'nome',
            sortOrder: SortOrder.DESC
          }
          findClient(lazy).then(data => {
            const doc : any = new jsPDF.default('portrait');
            doc.autoTable(exportColumns, data.clientes);
            doc.save('clientes.pdf');
          });
          
      });
  });
};

const exportExcel = () => {
import('xlsx').then((xlsx) => {
  const lazy = {
    first: 0,
    rows: totalRecords,
    page:  1,
    cliente: cliente,
    documento: documento,
    codigoClienteErp: codigoClienteErp,
    sortField: 'nome',
    sortOrder: SortOrder.DESC
  }
  findClient(lazy).then(data => {
    const users: any[] = []
    data.clientes.forEach((client:any) => users.push({nome:client.nome, email:client.email, documento:client.documento, inativo:client.inativo}))
    
    const worksheet = xlsx.utils.json_to_sheet(users);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
    });
    saveAsExcelFile(excelBuffer, 'clientes');
  });

    
});
};
const saveAsExcelFile = (excelBuffer: any, fileName: string) => {
import('file-saver').then((module) => {
    if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
});
};

const header = (
  <div className="flex-consulta align-items-center justify-content-end gap-2">
      <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
      <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
  </div>
);

const maskCpfCnpj = (input:string) =>{
 
  if(input.length > 14){
    return cnpj(input)
  }
  else{
    return cpf(input)
  }
}


  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      cliente: cliente,
      documento: documento,
      codigoClienteErp: codigoClienteErp,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      cliente: cliente,
      documento: documento,
      codigoClienteErp: codigoClienteErp,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const startContent = (
    <React.Fragment>
      <div  style={{background:'transparent'}}>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <div className="flex-auto">
                      <label htmlFor="cliente" className="font-bold block mb-2">{t('nome')} </label>
                      <InputText id="cliente" onBlur={(e)=>changeCliente(e.target.value)} style={{width: '20rem'}} required></InputText>
                  </div>
                </div>
              </div>
       
            <div className="col p-fluid flex flex-wrap">
              <div>
              <div className="flex-auto">
                <label htmlFor="documento" className="font-bold block mb-2">{t('documento')} </label>
                <InputText id="documento" maxLength={18} max={18} onKeyUp={handleDocumento} onBlur={(e)=>changeDocumento(e.target.value)} style={{width: '20rem'}}></InputText>
              </div>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <div className="flex-auto">
                  <label htmlFor="codigoClienteErp" className="font-bold block mb-2">{t('codigoCliente')} </label>
                  <InputText id="codigoClienteErp" onBlur={(e)=>changeCodigoClienteErp(e.target.value)} style={{width: '20rem'}}></InputText>
                </div>
              </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );

  const loadLazyData = () => {
    findClient(lazyState).then(data => {setTotalRecords(data.total);setClients(data.clientes)}).catch(() => {setTotalRecords(0);setClients(undefined)});
  }

  const inativoBodyTemplate = (user:any) => {
    return <InputSwitch disabled checked={user.inativo} onChange={() => user.inativo = !user.inativo} />;
  };

  const editBodyTemplate = (client:any) => {
    return <Button icon="pi pi-pencil" onClick={() => onEdit(client)} style={{background:'transparent', color:'gray', border: 'none'}} />;
  };
  
  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <div>
      <div>
        <Toolbar start={startContent} />
        <br></br>
        <br></br>
        <DataTable value={clients} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros" header={header}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column field="codigoClienteErp" sortable header={t('codigoCliente')}  style={{width: '15rem'}}/>
           <Column field="documento" sortable header={t('documento')}  />
           <Column field="nome" sortable header={t('nome')}  />
           <Column field="email" sortable sortField='email' header={t('email')}  />
           <Column header={t('inativo')} body={inativoBodyTemplate} /> 
           <Column body={editBodyTemplate} />
        </DataTable> 
      </div>
    </div>
  )
}


