
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function HistoricoInfo({
  historicos,
  label,
  showHist
}: {
  historicos: any,
  label: string,
  showHist : boolean 
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={(showHist ? t('historico') : '') + ' ' + label} style={{width:'98%'}}>
       
       <DataTable size="small" value={historicos} tableStyle={{ minWidth: '50rem' }}>
          <Column field="historico" header={label}></Column>
          <Column field="data" header={t('data')}></Column>
      </DataTable>
    </Panel>
  )
}
