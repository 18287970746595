import { http } from '../../config/axios'

export default function getCache(id:String): Promise<String> {
  return http
    .get('api/web/process/' + id)
    .then((res) => {
      return res.data as String
    })
    .catch((error) => {
      console.error('Erro getCache', error)
      throw Error(error.response.data)
    })
}
