import { http } from '../../config/axios'

 const graficoConsulta = (): Promise<any> => {
  
  return http
    .get('api/consulta/grafico')
    .then((res) => {
      return res.data as any
    })
    .catch((error) => {
      console.error('Erro graficoConsulta', error)
      throw Error(error.response.data)
    })
}

export default graficoConsulta;
