import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";

const NadaConsta = () => {
    return (
        <>
            <Card style={{backgroundColor: "rgba(101, 214, 173, 0.1)", color: "rgb(39, 171, 131)", border: "1px solid rgb(101, 214, 173)"}}>
                <div className="flex-score align-items-center">
                    <Avatar style={{backgroundColor: "rgba(101, 214, 173, 0.1)"}} icon="pi pi-verified" size="xlarge" shape="circle" />
                    <span style={{paddingLeft: "1rem"}}><b>Nada Consta</b></span>
                </div> 
            </Card>
        </>
    )
  }
export default NadaConsta;