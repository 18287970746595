import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Tree, TreeCheckboxSelectionKeys, TreeExpandedKeysType, TreeMultipleSelectionKeys } from 'primereact/tree';
import { useGlobalState } from '../../../components/globalState/GlobalStateProvider';
import { Checkbox } from "primereact/checkbox";
import { Divider } from 'primereact/divider';
import { FilterWeb } from '../../../interface/FilterWeb';
import { Fieldset } from 'primereact/fieldset';
import { InputNumber } from 'primereact/inputnumber';
import { Nullable } from 'primereact/ts-helpers';
import { PickList } from 'primereact/picklist';


export default function FiltroPJ({
    onProcess,
    onClose,
    msgs
  }: {
    onProcess: (filterWeb : FilterWeb) => void
    onClose: () => void,
    msgs:any
  }) {
  const { t } = useTranslation();
  const {state} = useGlobalState();
  const [nodes, setNodes] = useState<any[]>([]);
  const [cnpj, setCnpj] = useState<string>("");
  const [selectedKeys, setSelectedKeys] = useState<string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | any | null>(null); 
  const [selectedOptionCheck, setSelectedOptionCheck] = useState<any[]>([]);
  const [expandedKeys] = useState<TreeExpandedKeysType>({'QUODVERIFIQPJ': true, '"QUODPJ"': true});
  const [valor, setValor] = useState<number|null|undefined>(null);
  const [arrojado, setArrojado] = useState<boolean|undefined>(false);
  const [usual, setUsual] = useState<boolean|undefined>(false);
  const [conservador, setConservador] = useState<boolean|undefined>(false);
  const [targets, setTargets] = useState<any[]>([]);

  const expandNode = (node:any, _expandedKeys:any) => {
    if (node.children && node.children.length) {
        _expandedKeys[node.key] = true;

        for (let child of node.children) {
            expandNode(child, _expandedKeys);
        }
    }
  };

  const validCnpj = (documento:string) => {
    if(verifyCnpj(documento)){
      msgs.current?.clear();
      
    }
    else{
      window.scrollTo(0, 0);
      msgs.current?.clear();
        msgs.current?.show([
            { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: 'Documento inválido', closable: false }
          
        ]);
    }
  }

  const verifyCnpj = (cnpj:string) => {
 
      cnpj = cnpj.replace(/[^\d]+/g,'');
   
      if(cnpj == '') return false;
       
      if (cnpj.length != 14)
          return false;
   
      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" || 
          cnpj == "11111111111111" || 
          cnpj == "22222222222222" || 
          cnpj == "33333333333333" || 
          cnpj == "44444444444444" || 
          cnpj == "55555555555555" || 
          cnpj == "66666666666666" || 
          cnpj == "77777777777777" || 
          cnpj == "88888888888888" || 
          cnpj == "99999999999999")
          return false;
           
      // Valida DVs
      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0,tamanho) + '';
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
              pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != parseInt(digitos.charAt(0)))
          return false;
           
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
              pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != parseInt(digitos.charAt(1)))
            return false;
             
      return true;
      
  
  }


  
  const createOptions = (optionElement : any) =>{
    const option : any[] = [];

    optionElement.permissoes.filter((f: {
      tipoConsulta: string; 
    }) => f.tipoConsulta ===  'PJ' || f.tipoConsulta === 'PFPJ')
    .sort( (a:any,b:any) => {
      {
        if(!a.ordenacao){
          return 1;
        }

        if(!b.ordenacao){
          return -1;
        }

        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        return 0;
      }
    } )
    .map((element: any) => {
        option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes ? createPermissoesFilhos(element.permissoes.filter((f: {
          tipoConsulta: string; 
        }) => f.tipoConsulta ===  'PJ' || f.tipoConsulta === 'PFPJ')) : []});
            });
      
    
  
            expandAll(option)      
    return option;
  }

  const expandAll = (option:any) => {
    let _expandedKeys = {};

    for (let node of option) {
        expandNode(node, _expandedKeys);
    }

   return _expandedKeys;
};

  const createPermissoesFilhos = (permissoes:any[]) => {
    const option : any[] = [];
    if(permissoes){
      permissoes
      .sort( (a:any,b:any) => {
        {
          if(!a.ordenacao){
            return 1;
          }
  
          if(!b.ordenacao){
            return -1;
          }
  
          if (a.ordenacao > b.ordenacao) {
            return 1;
          }
          if (a.ordenacao < b.ordenacao) {
            return -1;
          }
          return 0;
        }
      } )
      .forEach((element: {
        children: any; label: string; value: string; permissoes:any[]  
      }) => {
        if(element.value == 'QUODPJINDICADORESNEGOCIO_PERFIL' || 
          element.value == 'QUODPJINDICADORESNEGOCIO_MERCADO' ||
          element.value == 'QUODPJINDICADORESNEGOCIO_GESTAO' ||
          element.value == 'QUODPJINDICADORESNEGOCIO_CENARIO' ||
          element.value == 'QUODPJINDICADORESNEGOCIO_PONTUALIDADE'){
          if(targets.filter((s)=>s.value===element.value).length === 0){
            targets.push({value : element.value, target : []})
          }
          
        }
        option.push({label:element.label, key:element.value, Collapse:true, pickList: targets.filter((s) => s.value === element.value).length != 0 ? element.permissoes : undefined, children: element.permissoes && targets.filter((s) => s.value === element.value).length === 0 ? createPermissoesFilhos(element.permissoes.filter((f: {
          tipoConsulta: string; 
        }) => f.tipoConsulta === 'PJ' || f.tipoConsulta === 'PFPJ')) : []});
      });
    }
    return option;
  }

  const processConsulta = () => {

    let opcoes: string[] = [];
    if(selectedKeys){
      Object.getOwnPropertyNames(selectedKeys).map((item) => opcoes.push(item));
    }
   
    selectedOptionCheck.map((item) => opcoes.push(item.value));
    const filteWeb :FilterWeb = {
      documento: cnpj,
      consultas: opcoes,
      valor: valor,
      arrojado: arrojado,
      usual:usual,
      conservador: conservador,
      indicadores: targets.filter((s:any) => opcoes.includes(s.value))
    };
    onProcess(filteWeb);
  }

  const onChange = (event:any, key:string) => {
    const targetsOut = [{value: key, target: event.target}]
    targets.filter((s:any) => s.value !== key).map((item:any) => targetsOut.push(item));
    setTargets(targetsOut)
  };

  const pickItemTemplate = (item:any) => {
    return (
        <div className="flex flex-wrap p-2 align-items-center gap-3">
            <div className="flex-1 flex flex-column gap-2">
                <span className="font-bold">{item.label}</span>
            </div>
        </div>
    );
  };

  const nodeTemplate = (node: any, options: { className: string | undefined; }) => {
    let label = <span>{node.label}</span>;
    if (node.children.length !== 0) {
       label = <b>{node.label}</b>;
    }
    if(node.key === 'QUODVERIFIQPJ_SCORE' && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes('QUODVERIFIQPJ_SCOREBOLETO')){
      node.selectable = false
    }
    if(node.key === 'QUODVERIFIQPJ_SCORE' && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes('QUODVERIFIQPJ_SCOREBOLETO') && Object.getOwnPropertyNames(selectedKeys)?.includes('QUODVERIFIQPJ_SCORE')){
      node.selectable = true
      delete selectedKeys.QUODVERIFIQPJ_SCOREBOLETO; 
      console.log(selectedKeys)
    }
    if(node.key === 'QUODVERIFIQPJ_SCOREBOLETO' && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes('QUODVERIFIQPJ_SCORE')){
      node.selectable = false
    }

    

    if(node.key.includes('QUODVERIFIQPJ_MAISNEGOCIO') && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes('QUODVERIFIQPJ_MAISNEGOCIO') ){
      return <>
      <Fieldset onClick={(e)=> e.stopPropagation()} legend="Mais Negócio">
                  <div className="flex-auto">
                    <label htmlFor="currency" className="font-bold block mb-2 required">{t('valor')}</label>
                    <InputNumber inputId="currency" onValueChange={(e) => setValor(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
                  </div>
                  <br></br>
                  <label htmlFor="currency" className="font-bold block mb-2 required">{t('perfilRisco')}</label>
                  <br></br>
                  <div className="flex align-items-center">
                    <Checkbox inputId="arrojado" name="arrojado" onChange={e => {e.originalEvent?.stopPropagation(); setArrojado(e.checked)}} checked={arrojado? arrojado : false}  />
                    <label htmlFor="arrojado" className="ml-2">{t('arrojado')}</label>
                  </div>
                  <br></br>
                  <div className="flex align-items-center">
                    <Checkbox inputId="usual" name="usual" onChange={e =>{e.originalEvent?.stopPropagation(); setUsual(e.checked)}} checked={usual? usual : false}  />
                    <label htmlFor="usual" className="ml-2">{t('usual')}</label>
                  </div>
                  <br></br>
                  <div className="flex align-items-center">
                    <Checkbox inputId="conservador" name="conservador" onChange={e =>{e.originalEvent?.stopPropagation(); setConservador(e.checked)}} checked={conservador? conservador : false}  />
                    <label htmlFor="conservador" className="ml-2">{t('conservador')}</label>
                  </div>
                </Fieldset>
      </>
    }
    if(node.key.includes('QUODMAISNEGOCIOPJ') && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes('QUODMAISNEGOCIOPJ') ){
      return <>
      <Fieldset onClick={(e)=> e.stopPropagation()} legend="Mais Negócio">
                  <div className="flex-auto">
                    <label htmlFor="currency" className="font-bold block mb-2 required">{t('valor')}</label>
                    <InputNumber inputId="currency" onValueChange={(e) => setValor(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
                  </div>
                  <br></br>
                  <label htmlFor="currency" className="font-bold block mb-2 required">{t('perfilRisco')}</label>
                  <br></br>
                  <div className="flex align-items-center">
                    <Checkbox inputId="arrojado" name="arrojado" onChange={e => {e.originalEvent?.stopPropagation(); setArrojado(e.checked)}} checked={arrojado? arrojado : false}  />
                    <label htmlFor="arrojado" className="ml-2">{t('arrojado')}</label>
                  </div>
                  <br></br>
                  <div className="flex align-items-center">
                    <Checkbox inputId="usual" name="usual" onChange={e =>{e.originalEvent?.stopPropagation(); setUsual(e.checked)}} checked={usual? usual : false}  />
                    <label htmlFor="usual" className="ml-2">{t('usual')}</label>
                  </div>
                  <br></br>
                  <div className="flex align-items-center">
                    <Checkbox inputId="conservador" name="conservador" onChange={e =>{e.originalEvent?.stopPropagation(); setConservador(e.checked)}} checked={conservador? conservador : false}  />
                    <label htmlFor="conservador" className="ml-2">{t('conservador')}</label>
                  </div>
                </Fieldset>
      </>
    }
    if((node.pickList && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes(node.key))){
      console.log(node);
      return <>
              <Fieldset onClick={(e)=> e.stopPropagation()} legend={node.label}>
                <PickList dataKey="value" onChange={(e) => {onChange(e, node.key)}} itemTemplate={pickItemTemplate} showSourceControls={false} showTargetControls={false} source={node.pickList.filter((s:any) => {return targets.filter((s:any) => s.value === node.key)[0].target.filter((s2:any) => s.value === s2.value).length == 0} )} target={targets.filter((s:any) => s.value === node.key)[0].target}  filter filterBy="label" 
                  sourceHeader="Indicadores" targetHeader="Selecionados" sourceStyle={{ height: '24rem' }} targetStyle={{ height: '24rem' }}
                  sourceFilterPlaceholder="Busca" targetFilterPlaceholder="Busca" />
              </Fieldset>
              </>   
    }
    return label;
  }

  return (<>
    <Panel header={t('consultaPJ')}>
        <div className=" p-fluid flex flex-wrap gap-3">
            <div className="flex-auto">
                <label htmlFor="cnpj" className="font-bold block mb-2 required">CNPJ</label>
                <InputMask id="cnpj" value='cnpj' onBlur={(e)=>{setCnpj(e.target.value);validCnpj(e.target.value)}} mask="99.999.999/9999-99" placeholder="999.999.999-99" required></InputMask>
            </div>
            <br></br>
            
            {state.user.consultas.filter((f: { tipoConsulta: string; }) => f.tipoConsulta === 'PJ' || f.tipoConsulta === 'PFPJ').sort( (a:any,b:any) => {
      {
        if(!a.ordenacao){
          return 1;
        }

        if(!b.ordenacao){
          return -1;
        }

        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        return 0;
      }
    } ).map((_item: any) => {
                    return ( <div key={_item.value+'_main'}>
                        <b>{_item.label}</b>
                        <div key={_item.value} className="flex flex-column gap-3">
                            <br></br>
                            <Tree key={_item.value+'_tree'} nodeTemplate={nodeTemplate} expandIcon={undefined} collapseIcon={undefined} expandedKeys = {expandAll(createOptions(_item))} value={createOptions(_item)} selectionMode="checkbox" selectionKeys={selectedKeys} onSelectionChange={(e) => {setSelectedKeys(e.value);  }} style={{border:'none', marginLeft:'-2.0rem', background:'transparent'}}  />
                        </div>
                        <br></br>
                        <Divider />
                        <br></br>
                        </div>);
                })}
         
        </div>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Consultar"  onClick={processConsulta} />
            
        </div> 
            
    </Panel>
   
    </>
  )
}
