
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




export default function Socios({
  socios
}: {
  socios
  : any
}) {
  const { t } = useTranslation();
  
  return (
    <Panel header={t('socios')} style={{width:'98%'}}>
       
       <DataTable size="small" value={  socios
} tableStyle={{ minWidth: '50rem' }}>
          <Column field="documento" header={t('documento')}></Column>
          <Column field="status" header={t('status')}></Column>
          <Column field="nome" header={t('nome')}></Column>
          <Column field="tipo" header={t('tipo')}></Column>
          <Column field="participacao" header={t('participacao')}></Column>
          <Column field="dataInicio" header={t('dataEntrada')}></Column>
          <Column field="dataFim" header={t('dataSaida')}></Column>
          <Column field="dataAtualizacao" header={t('dataAtualizacao')}></Column>
          <Column field="qsaAtualizado" header={t('qsaAtualizado')}></Column>
          <Column field="statusRestricao" header={t('restricao')}></Column>
      </DataTable>
    </Panel>
  )
}
