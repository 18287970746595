
import { useTranslation } from "react-i18next";
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import NadaConsta from "../../../components/nadaConsta";




export default function Apontamentos({
  apontamentos
}: {
  apontamentos: any
}) {
  const { t } = useTranslation();
  const getLastOccurrence = () => {
    let data:Date|null = null
    if(!apontamentos)
      return data;
    apontamentos.forEach((element: { dataRegistro: Date; }) => {
      if(data == null || data < element.dataRegistro)
        data = element.dataRegistro
    });
    return data;
  }
  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> { apontamentos && apontamentos.length > 0 ? (<span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('apontamentos')}</span></span>) : (<span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('apontamentos')}</span>) }</div>;
  }
  const getTotal = () => {
    let total:GLfloat = 0.0;
    if(!apontamentos)
      return total;
    apontamentos.forEach((element: { valor: string; }) => {
      total = total + parseFloat(element.valor.replace(".", "").replace(",", "."))
    });
    return total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});;
  }
  
  return (
    <Panel headerTemplate={headerTemplate} style={{width:'98%'}}>
       {!apontamentos || apontamentos.length === 0 ? ( <NadaConsta />) : (<>

       <div style={{paddingBottom:'2rem', paddingLeft:'5rem'}}><span style={{padding:'2rem'}}><b>VALOR TOTAL:</b> {getTotal()} </span><span style={{padding:'2rem'}}><b>QUANTIDADE DE PENDÊNCIAS HOJE:</b> {apontamentos.length} </span><span style={{padding:'2rem'}}><b>ÚLTIMA OCORRÊNCIA:</b> {getLastOccurrence()} </span></div>      
       <DataTable size="small" value={apontamentos} tableStyle={{ minWidth: '50rem' }}>
          <Column field="dataRegistro" header={t('dataRegistro')}></Column>
          <Column field="dataVencimento" header={t('dataVencimento')}></Column>
          <Column field="natureza" header={t('natureza')}></Column>
          <Column field="contrato" header={t('contrato')}></Column>
          <Column field="cnpjCredor" header={t('cnpjCredor')}></Column>
          <Column field="nomeCredor" header={t('credor')}></Column>
          <Column field="cidadeUF" header={t('cidadeUF')}></Column>
          <Column field="tipo" header={t('tipo')}></Column>
          <Column field="valor" header={t('valor')}></Column>
      </DataTable>
      </>)}
    </Panel>
  )
}
