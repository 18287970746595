import { http } from '../../config/axios'

export default function putParametrosService(parametros: any): Promise<string> {
  return http
    .put('api/parametro-aplicacao', parametros)
    .then((res) => {
      return res.data as string;
    })
    .catch((error) => {
      console.error('Erro ao atualizar parâmetros', error);
      throw Error(error.response.data);
    });
}