type Props = {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element),
    onClick : () => void
}

const IconButton = ({children, onClick} : Props) => {
    return (
        <>
            <button onClick={onClick}><>{children}</></button> 
        </>
    )
  }
export default IconButton;