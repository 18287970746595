import { http } from '../../config/axios'
import { FiltrarRelatorio } from '../../interface/FiltrarRelatorio'

export default function postRelatorio(filtrarRelatorio: FiltrarRelatorio): Promise<string> {
  return http
    .post('api/relatorio', filtrarRelatorio)
    .then((res) => {
      return res.data as string
    })
    .catch((error) => {
      console.error('Erro ao Relatorio', error)
      throw Error(error.response.data)
    })
}
