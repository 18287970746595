
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from "primereact/chip";
import { useState } from "react";




export default function AcoesCompleta({
  acoes
}: {
  acoes: any
}) {
  const { t } = useTranslation();
  const [list, setList] = useState({
    itemsDisplayed: 20,
    data: acoes.slice(0, 20)
  })

  const scrollTable = () => {
    if (list.itemsDisplayed + 10 <= acoes.length) {
      setList({
        itemsDisplayed: list.itemsDisplayed + 10,
        data: acoes.slice(0, list.itemsDisplayed + 10)
      })
    }
  }
  window.onscroll = () => scrollTable()
  
  return (
    <Panel header={t('acoesCompleta')} style={{width:'98%'}}>
       {list.data.map((acao:any) => {
        return (
          <>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('processo')}</label>
                    <Chip style={{width:"100%"}} label={acao.numero} />
                </div>
              </div>
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('comarca')}</label>
                    <Chip style={{width:"100%"}} label={acao.comarca} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('vara')}</label>
                    <Chip style={{width:"100%"}} label={acao.vara} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('cidadeUF')}</label>
                    <Chip style={{width:"100%"}} label={acao.cidadeUF} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('tipo')}</label>
                    <Chip style={{width:"100%"}} label={acao.tipo} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('dataDistribuicao')}</label>
                    <Chip style={{width:"100%"}} label={acao.dataDistribuicao} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('valor')}</label>
                    <Chip style={{width:"100%"}} label={acao.valor ? acao.valor : 'Não Informado'} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('origem')}</label>
                    <Chip style={{width:"100%"}} label={acao.origem} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('assunto')}</label>
                    <Chip style={{width:"100%"}} label={acao.assunto ? acao.assunto : 'Não Informado'} />
                </div>
              </div>
              
            </div>
            <br></br>
            <DataTable header={t('partes')} size="small" value={acao.partes} tableStyle={{ minWidth: '50rem' }}>
                <Column field="tipo" header={t('tipo')}></Column>
                <Column field="data" header={t('data')}></Column>
                <Column field="polo" header={t('polo')}></Column>
                <Column field="advogado" header={t('advogados')}></Column>
                <Column field="documento" header={t('documento')}></Column>
                <Column field="nome" header={t('nome')}></Column>
            </DataTable>
            <br></br>
            <br></br>
            {acao.movimentos ? (
              <>
                <br></br>
               
                <DataTable header={t('movimentacoes')} size="small" value={acao.movimentos} tableStyle={{ minWidth: '50rem' }}>
                  <Column field="data" header={t('data')}></Column>
                  <Column field="descricao" header={t('descricao')}></Column>
                </DataTable>
             
              </>
            ):''}
          <br></br>
          <div className="line"></div>
          <br></br>
          </>
          
        )
        
       })}
    </Panel>
  )
}
