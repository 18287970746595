import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import findArquivoRegistro from '../../service/findArquivoRegistro';
import getArquivoRegistroDownload from '../../service/getArquivoRegistroDownload';
import React from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Nullable } from "primereact/ts-helpers";
import { SortOrder } from 'primereact/api';
import { Button } from 'primereact/button';
import { useGlobalState } from '../../components/globalState/GlobalStateProvider';


export default function ArquivoRegistroPage() {
  const { t } = useTranslation();
  const [arquivos, setArquivos] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState<Nullable<Date>>(new Date());
  const [dataFinal, setDataFinal] = useState<Nullable<Date>>(new Date());
  const {state} = useGlobalState();
  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
  }
  const formatDate = (date:Nullable<Date>) => {
    if(date){
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('-');
    }
   
  }

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    data: formatDate(data),
    dataFinal: formatDate(dataFinal),
    sortField: 'data',
    sortOrder: SortOrder.DESC
  });

  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const changeData = (data:Nullable<Date>) => {
    if(data){
      setData(data);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        sortField: 'data',
        sortOrder: SortOrder.DESC
      });
    }
  }

  const changeDataFinal = (dataFi:Nullable<Date>) => {
    if(dataFi){
      setDataFinal(dataFi);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFi),
        sortField: 'data',
        sortOrder: SortOrder.DESC
      });
    }
  }


  const loadLazyData = () => {
    findArquivoRegistro(lazyState).then(data => {setTotalRecords(data.total);setArquivos(data.arquivos)}).catch(() => {setTotalRecords(0);setArquivos(undefined)});
  }
  

const startContent = (
  <React.Fragment>
    <div  style={{background:'transparent'}}>
    <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div >
              <label htmlFor="data" className="font-bold block mb-2">{t('dataInicial')} </label>
              <Calendar id='data' value={data} onChange={(e) => changeData(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
            </div>
          </div>
    </div>
    <div className="grid">
          
    </div>
    </div>
    <div style={{background:'transparent', marginLeft:'10rem'}}>
      <div className="grid">
            
            <div className="col p-fluid flex flex-wrap">
              <div >
                <label htmlFor="dataFinal" className="font-bold block mb-2">{t('dataFinal')} </label>
                <Calendar id='dataFinal' value={dataFinal} onChange={(e) => changeDataFinal(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
              </div>
            </div>
      </div>
      <div className="grid">
            
           
      </div>
      </div>
  </React.Fragment>
);

  const endContent = (
    <React.Fragment>
      
    </React.Fragment>
);

const download = (arquivo:any, encriptado:boolean) => {
  getArquivoRegistroDownload(arquivo.id, encriptado).then( (blobFile:Blob) => {
    const url = window.URL.createObjectURL(
      new Blob([blobFile], {type: "octet/stream"}),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      encriptado ? arquivo.nome.replaceAll('.txt', '.gpg') : arquivo.nome,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  })
};

const downloadTemplate = (arquivo:any) => {
  return <>
          {state.user.funcoes.includes('ARQUIVOREGISTRO_DOWNLOAD') && (
              <>             
              <Button icon="pi pi-download" onClick={() => {download(arquivo, false)}} style={{background:'transparent', color:'gray', border: 'none'}} />
              <Button icon="pi pi-lock" onClick={() => {download(arquivo, true)}} style={{background:'transparent', color:'gray', border: 'none'}} />
              </>

          )}
          
          
        </>
};

  useEffect(() => {
    loadLazyData();
  },[lazyState])

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-folder" style={{ fontSize: '2.0rem' }}></i> {t('arquivosRegistro')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Toolbar start={startContent} end={endContent} />
        <br></br>
         <br></br>
        <DataTable value={arquivos} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros"
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column field="data" header={t('data')}  />
           <Column field="nome" header={t('nome')}  />
           <Column field="tipo" header={t('tipo')}  />
           <Column field="quantidadeInclusao" header={t('qt_inclusao')}  />
           <Column field="quantidadeExclusao"  header={t('qt_exclusao')}  />
           <Column style={{width: '11%'}} body={downloadTemplate} />
           
          
        </DataTable> 
      </div>
                
    </Container>
  )
}
