import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findRegistro from '../../service/findRegistro';
import { SortOrder } from 'primereact/api';
import { Button } from 'primereact/button';
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';




export default function RegistroList({onEdit, date}:{onEdit:(registro:any)=>void, date:Date}) {
  const { t } = useTranslation();
  const [registros, setRegistros] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const [nome, setNome] = useState<Nullable<String | null>>(null);
  const [documento, setDocumento] = useState<Nullable<String | null>>(null);
  const [data, setData] = useState<Nullable<Date>>(new Date());
  const [dataFinal, setDataFinal] = useState<Nullable<Date>>(new Date());
  const [status, setStatus] = useState<Nullable<String | null>>(null);
  
  const listaStatus = [
    { name: 'Selecione', code: null },
    { name: 'Incluído', code: 'INCLUIDO' },
    { name: 'Registrado', code: 'REGISTRADO' },
    { name: 'Excluído', code: 'EXCLUIDO' },
    { name: 'Cancelado', code: 'CANCELADO' }
];
  
  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
  }

  const formatDate = (date:Nullable<Date>) => {
    if(date){
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('-');
    }
   
  }

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    data: formatDate(data),
    dataFinal: formatDate(dataFinal),
    nome: nome,
    documento: documento,
    status: status,
    sortField: 't.dataInclusao',
    sortOrder: SortOrder.DESC
  });

  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      nome: nome,
      documento: documento,
      status: status,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      nome: nome,
      documento: documento,
      status: status,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const changeData = (data:Nullable<Date>) => {
    if(data){
      setData(data);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        nome: nome,
        documento: documento,
        status: status,
        sortField: 't.dataInclusao',
        sortOrder: SortOrder.DESC
      });
    }
  }

  const changeDataFinal = (dataFi:Nullable<Date>) => {
    if(dataFi){
      setDataFinal(dataFi);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFi),
        nome: nome,
        documento: documento,
        status: status,
        sortField: 't.dataInclusao',
        sortOrder: SortOrder.DESC
      });
    }
  }
  

  const changeNome = (nome:Nullable<String>) => {
    nome = nome ? nome : null
      setNome(nome);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        nome: nome,
        documento: documento,
        status: status,
        sortField: 't.dataInclusao',
        sortOrder: SortOrder.DESC
      });
    
  }

  const changeStatus = (status:Nullable<any>) => {
    status = status ? status : null
    if(status instanceof Object){
      status = null
    }
    console.log(status)
      setStatus(status);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        nome: nome,
        documento: documento,
        status: status,
        sortField: 't.dataInclusao',
        sortOrder: SortOrder.DESC
      });
    
  }

  const changeDocumento = (doc:Nullable<String>) => {
    doc = doc ? doc.replaceAll('.','').replaceAll('-','').replaceAll('/','') : null
       setDocumento(doc);
       setlazyState({
         first: lazyState.first,
         rows: lazyState.rows,
         page: (lazyState.first / lazyState.rows) + 1,
         data: formatDate(data),
         dataFinal: formatDate(dataFinal),
         nome: nome,
         documento: doc,
         status: status,
         sortField: 't.dataInclusao',
         sortOrder: SortOrder.DESC
       });
     
   }

   const cnpj = (v:string) =>{
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
}

const cpf = (v:string) => {
    v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                             //de novo (para o segundo bloco de números)
    v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}

const maskCpfCnpj = (input:string) =>{
 console.log(input)
  if(input.length > 14){
    return cnpj(input)
  }
  else{
    return cpf(input)
  }
}

const cols = [
  { field: 'cliente', header: 'Cliente' },
  { field: 'documento', header: 'Documento' },
  { field: 'nome', header: 'Nome' },
  { field: 'contrato', header: 'Contrato' },
  { field: 'valor', header: 'Valor' },
  { field: 'ocorrencia', header: 'Dt. Ocorrência' },
  { field: 'dataInclusao', header: 'Dt. Inclusão' },
  { field: 'status', header: 'Status' }
];
const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
const exportPdf = () => {
  import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
          const lazy = {
            first: 0,
            rows: totalRecords,
            page: 1,
            data: formatDate(data),
            dataFinal: formatDate(dataFinal),
            nome: nome,
            documento: documento, 
            status: status,
            sortField: 'dataInclusao',
            sortOrder: SortOrder.DESC
          }
          findRegistro(lazy).then(data => {
            const doc : any = new jsPDF.default('portrait');
            doc.autoTable(exportColumns, data.registros);
            doc.save('registros.pdf');
          });
          
      });
  });
};

const exportExcel = () => {
import('xlsx').then((xlsx) => {
  const lazy = {
    first: 0,
    rows: totalRecords,
    page: 1,
    data: formatDate(data),
    dataFinal: formatDate(dataFinal),
    nome: nome,
    documento: documento,
    status: status,
    sortField: 'dataInclusao',
    sortOrder: SortOrder.DESC
  }
  findRegistro(lazy).then(data => {
    const worksheet = xlsx.utils.json_to_sheet(data.registros);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
    });
    saveAsExcelFile(excelBuffer, 'registros');
  });

    
});
};

const handleDocumento = (event:any) => {
event.target.value =  maskCpfCnpj(event.target.value)
}

const saveAsExcelFile = (excelBuffer: any, fileName: string) => {
import('file-saver').then((module) => {
    if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
});
};
const header = (
  <div className="flex-consulta align-items-center justify-content-end gap-2">
      <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
      <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
  </div>
);

  const startContent = (
    <React.Fragment>
      <div  style={{background:'transparent'}}>
        <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label htmlFor="data" className="font-bold block mb-2">{t('dataInicial')} </label>
                  <Calendar id='data' value={data} onChange={(e) => changeData(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                </div>
              </div>
        </div>
        <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div>
                <div className="flex-auto">
                  <label htmlFor="documento" className="font-bold block mb-2">{t('documento')} </label>
                  <InputText id="documento" maxLength={18} max={18} onKeyUp={handleDocumento} onBlur={(e)=>changeDocumento(e.target.value)} style={{width: '20rem'}}></InputText>
                </div>
                </div>
              </div>
        </div>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label htmlFor="status" className="font-bold block mb-2">{t('status')} </label>
                  <Dropdown value={status} onChange={(e) => changeStatus(e.value)} options={listaStatus} optionLabel="name" 
                    placeholder="Selecione o Status" optionValue='code' className="w-full md:w-14rem" />
                </div>
              </div>
        </div>
      </div>
      <div style={{background:'transparent', marginLeft:'10rem'}}>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label htmlFor="dataFinal" className="font-bold block mb-2">{t('dataFinal')} </label>
                  <Calendar id='dataFinal' value={dataFinal} onChange={(e) => changeDataFinal(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                </div>
              </div>
        </div>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <div className="flex-auto">
                        <label htmlFor="nome" className="font-bold block mb-2">{t('nome')} </label>
                        <InputText id="nome" onBlur={(e)=>changeNome(e.target.value)} style={{width: '20rem'}} required></InputText>
                    </div>
                </div>
              </div>
        </div>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <div className="flex-auto" style={{visibility:'hidden'}}>
                    <label htmlFor="text" className="font-bold block mb-2">{t('nome')} </label>
                    <InputText id="text"  style={{width: '20rem'}} required></InputText>
                    </div>
                </div>
              </div>
          </div>
      </div>
      
      
    </React.Fragment>
  );

  const loadLazyData = () => {
    findRegistro(lazyState).then(data => {setTotalRecords(data.total);setRegistros(data.registros)}).catch(() => {setTotalRecords(0);setRegistros(undefined)});
  }

  const actionTemplate = (event:any) => {
    return (
        <div className="flex flex-wrap gap-2" style={{display:'flex', justifyContent:'end'}}>
            <Button icon="pi pi-eraser" onClick={() => onEdit(event)} style={{background:'transparent', color:'gray', border: 'none'}} />
        </div>
    );
  };

  
  
  useEffect(() => {
    loadLazyData();
  },[lazyState, date])

  return (
    <div>
      <div className="card">
        <Toolbar start={startContent} />
        <br></br>
         <br></br>
        <DataTable value={registros} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros" header={header}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column field="cliente" header={t('cliente')}  />
           <Column field="documento" sortable  header={t('documento')}  />
           <Column field="nome" sortable  header={t('nome')}  />
           <Column field="contrato"  sortable header={t('contrato')}  />
           <Column field="valor" sortable  header={t('valor')}  />
           <Column field="ocorrencia" sortable  header={t('dtOcorrencia')}  />
           <Column field="dataInclusao" sortable  header={t('dtInclusao')}  />
           <Column field="status" sortable  header={t('status')}  />
           <Column body={actionTemplate} />
           
        </DataTable> 
      </div>
    </div>
  )
}


