import { http } from '../../config/axios'

export default function getConsultaDownloadResponse(id:string) :Promise<Blob>{
    return http.get('/api/consulta/download/' + id, {
        responseType: 'blob'
    })
    .then((r) => r.data)
    .then((blobFile) => {
        return blobFile
    })
    .catch((error) => {
        console.error('Erro ao baicar download response')
        return new Blob()
    })
}