import { useDetectClickOutside } from "react-detect-click-outside";
import { useState } from "react";
import { merge } from "lodash";
import Button from "./Button";
import IconButton from "./IconButton";


 
const ContextMenu = ( {
    children = <></>,
    isIcon = false,
    buttonLabel = <></>,
    buttonProps = {},
    cardProps = {},
  }) => {
  const defaultCardProps = {
    css: {
      position: "absolute",
      right: 0,
      top: "56px",
      minWidth: "300px",
    },
  };
  const mergedCardProps = merge(defaultCardProps, cardProps);
  const [open, setOpen] = useState(false);
  const ButtonComponent = isIcon ? IconButton : Button;

  const handleToggleMenu = () => setOpen(!open);

  const handleCloseMenu = () => {
    if (!!open) setOpen(false);
  };

  const menuRef = useDetectClickOutside({ onTriggered: handleCloseMenu });

  return (
    <div style={{ position: "relative" }} ref={menuRef}>
      <ButtonComponent {...buttonProps} onClick={handleToggleMenu}>{buttonLabel}</ButtonComponent>
      {open && <div {...mergedCardProps}>{children}</div>}
    </div>
  );
};

export default ContextMenu;
