import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import UsuarioList from './UsuarioList';
import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import UsuarioCadastrar from './UsuarioCadastrar';
import { Messages } from 'primereact/messages';
import postUser from "../../service/postUser";
import putUser from '../../service/putUser';

export default function UsuarioPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const [user, setUser] = useState<any | null>(null);
  const labelButton = t('cadastrar')
  const saveUser = (user:any) => {
    if(user.id){
      putUser(user).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setUser(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    else{
      postUser(user).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setUser(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    
    
  }
  const startContent = (
    <React.Fragment>
        <Button label={labelButton} icon="pi pi-plus" style={{backgroundColor:'#183462'}} onClick={() => {setUser({})}}/>
    </React.Fragment>
);

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-user" style={{ fontSize: '2.0rem' }}></i> {t('usuarios')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!user && (
          <>
            <Toolbar start={startContent} />
            <br></br>
            <br></br>
            <UsuarioList onEdit={(user:any) => { setUser(user);}}></UsuarioList>
          </>
        )}
        {user && (
          <>
           <UsuarioCadastrar user={user} onSave={saveUser} onClose={() => {setUser(null)}}></UsuarioCadastrar>
          </>
        )}
      </div>
                
    </Container>
  )
}
